import React, {Component} from 'react';
import Select from 'react-select';
import {Link} from 'react-router-dom';
import Common from "./Common";
import axios from "axios";
import {Sugar} from 'react-preloaders';
import ClipLoader from "react-spinners/ClipLoader";

import logoCotiza from '../images/logo-cotiza.png';

const config = {
    headers: { Authorization: Common.tokenAPI, 'Conten-type' : 'multipart/form-data'}
};

function getBase64FomFile(img, callback){
    let fileReader = new FileReader();
    fileReader.addEventListener('load', function(evt){
      callback(fileReader.result);
    });
    fileReader.readAsDataURL(img);
}

export default class Cotización extends Component{

    constructor(props) {
        super(props);

        this.state ={
          optionsSabor : [],
          optionsRelleno : [],
          isGoing: false,
          disabled: true,
          selectProducto : '',
          selectSabor : '',
          value : '',
          tituloCampo : 'Porciones (mínimo 4 porciones)',
          producto : '',
          sabor : '',
          cantidad : 0,
          nombre: '',
          celular: 0,
          email: '',
          mensajeTorta: '',
          especificaciones: '',
          file: '',
          loading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleProducto = this.handleProducto.bind(this);
        this.handleRelleno = this.handleRelleno.bind(this);
        this.handleSabor = this.handleSabor.bind(this);
        this.fileInput = React.createRef();

        this.opctionsProducto=[
            {value: '1', label: 'Tortas Personalizadas (Fondant/Masa Elastica/Foto Torta/Torta en Crema)'},
            {value: '2', label: 'Cupcakes Personalizados'}
        ];

        axios.get(`${Common.urlAPI}/Sabor`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.saborId,
                    label: item.nombre
                    
                };
            });
      
            this.setState({optionsSabor : source});
        })

        axios.get(`${Common.urlAPI}/Relleno`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.rellenoId,
                    label: item.nombre
                    
                };
            });
      
            this.setState({optionsRelleno : source});
        })

    }


    handleProducto(selectedOption){

        this.setState({ selectProducto : selectedOption.value, producto : selectedOption.label});

        if(selectedOption.value === '1'){
            this.setState({tituloCampo : 'Porciones (mínimo 4 porciones)', disabled :  false})
        }else{
            this.setState({tituloCampo : 'Unidades (mínimo 6 unidades)', disabled :  true})
        }
    
    }

    handleSabor(selectedOption){

        this.setState({ selectSabor : selectedOption.value, sabor : selectedOption.label});
    
    }

    handleRelleno(value) {


        if(value !== null || value === '')
        {
          if(value.length <= 2){
    
            this.setState({value});
    
          }
          else{
            Common.notify("Maxímo 2 sabores", "error")
          }
        }else{
          this.setState({value});
        }
    }

    setField (e) {
        this.setState({[e.target.name]: e.target.value})
    }

    

    handleSubmit(event) {
       event.preventDefault();

       

       if(this.state.producto !== '' && this.state.sabor !== '' && this.value !== '' && this.state.cantidad !== 0 && this.state.nombre !== '' && this.state.celular !== 0 && this.state.email !== ''){
           if(this.state.celular.length === 9 && /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.state.email)){

            if(this.fileInput.current.files.length !== 0){
                let producto = this.state.producto;
                let sabor = this.state.sabor;
                let relleno1 = '';
                let relleno2 = '';
                if(this.state.value.length === 1) { relleno1 = this.state.value[0].label} else { relleno1 = this.state.value[0].label; relleno2 = this.state.value[1].label}
                let porciones = this.state.cantidad;
                let nombre = this.state.nombre;
                let celular = this.state.celular;
                let email = this.state.email;
                let mensajeTorta = this.state.mensajeTorta;
                let especificaciones = this.state.especificaciones;
                let nameImagen = this.fileInput.current.files[0].name

                if(this.state.isGoing === true ){
        
                    this.setState({ loading : true });

                    var JsonCotizacion;
                    const { history } = this.props

                    getBase64FomFile(this.fileInput.current.files[0], function(base64){
                        
                        JsonCotizacion = {
                            'producto' : producto,
                            'sabor' : sabor,
                            'relleno1' : relleno1,
                            'relleno2' : relleno2,
                            'cantidad' : porciones,
                            'nombre' : nombre,
                            'celular' : celular,
                            'email' : email,
                            'mensajetorta' : mensajeTorta,
                            'mensajeAdicional' : especificaciones,
                            'file' : base64,
                            'nameImagen' : nameImagen
                        };
                        axios.post(`${Common.urlAPI}/EnviarCorreo/Cotizacion`, JsonCotizacion , config)
                        .then((response) => {
                             if(response.status === 200){
                                history.push('/graciasCotizacion')
                             }else{
                                 Common.notify("Ocurrio un error, vuelva a intentarlo", "error")
                             }
                        })
        
                    })
                }else{
                    Common.notify("Aceptar términos Condiciones","error");
                }
            }else{
                Common.notify("Seleccionar una imagen","error");
            }
                

           }else{
            Common.notify("Ingresar el formato correcto","error");
           }
       }else{
        Common.notify("Completar todos los campos requeridos","error");
       }

        
    }
    
    handleInputChange(event) {

        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const name = target.name;

        this.setState({
        [name]: value
        });


    }

    render(){
        return(
             <section className="col-md-12">
                 <div className="container bg-cotiza">
                    <div className="card">
                        <h5 className="card-header">Cotiza Aquí <img src={logoCotiza}  /></h5>
                        <div className="card-body">
                            <h5 className="card-title"></h5>
                            <form>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                           <label>Elige un producto:</label>
                                           <Select
                                           value={this.opctionsProducto.filter(({value}) => value === this.state.selectProducto)}
                                           onChange={this.handleProducto}
                                           options ={this.opctionsProducto}
                                           placeholder="Seleccionar" />
                                       </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                           <label>Elige un Sabor:</label>
                                           <Select
                                           value={this.state.optionsSabor.filter(({value}) => value === this.state.selectSabor)}
                                           onChange={this.handleSabor}
                                           options = {this.state.optionsSabor} 
                                           placeholder="Seleccionar" />
                                       </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                           <label>Elige dos Rellenos:</label>
                                           <Select 
                                            value = {this.state.value}
                                            onChange={this.handleRelleno}
                                            isMulti
                                            options = {this.state.optionsRelleno}
                                            isDisabled = {this.state.displayRelleno}
                                            placeholder = {'Seleccionar'}/>
                                       </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                           <label>{this.state.tituloCampo}</label>
                                           <input type="number" name="cantidad" onChange={(e)=>this.setField(e)} className="form-control" />
                                       </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div >
                                        <label>
                                            Sube tu foto aquí
                                        <input 
                                        style={{marginTop: "9px"}} 
                                        type="file" ref={this.fileInput}
                                        accept="image/*"                                       
                                        />
                                        </label>
                                    </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                          <label>Nombre Completo</label>
                                          <input type="text" className="form-control" name="nombre" onChange={(e)=>this.setField(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                          <label>Número celular</label>
                                          <input type="number" className="form-control" name="celular" onChange={(e)=>this.setField(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                          <label>Correo electrónico</label>
                                          <input type="text" className="form-control" name="email" onChange={(e)=>this.setField(e)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mensaje en la torta</label>
                                            <textarea disabled={this.state.disabled} className="form-control" rows="4" placeholder="Ejm: Feliz Cumpleaños Giancarlo" name="mensajeTorta" onChange={(e)=>this.setField(e)}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Especificaciones Adicionales</label>
                                            <textarea className="form-control" rows="4" placeholder="Mensaje" name="especificaciones" onChange={(e)=>this.setField(e)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <label>
                                    <input 
                                    type="checkbox" 
                                    name="isGoing" 
                                    checked={this.state.isGoing}
                                    onChange={this.handleInputChange} />
                                    <p style={{color:"black", paddingLeft : "25px", marginTop: "-25px" }}>He Leido y Acepto los <Link className="tp-terminos" target="_blank" rel="noopener noreferrer"  to={'/terminosCondiciones'}>Términos y Condiciones</Link></p>
                                </label>
                                {
                                    this.state.loading ? <button disabled={true} style={{opacity : "0.5", cursor : "default"}} class="sc-htpNat btn-ctenviar">ENVIAR&nbsp;&nbsp;<ClipLoader size={25} loading={this.state.loading} /></button>
                                    
                                    : <button class="sc-htpNat btn-ctenviar" onClick={(e) => this.handleSubmit(e)}>ENVIAR</button>
                                }
                                
                            </form>
                        </div>
                    </div>
                 </div>
                 <Sugar color= "#ffc107" customLoading={this.state.loading} />
             </section>
        )
    }
}