import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import gracias from '../images/gracias.png'

export default class GraciasCompra extends Component{
    render(){
        return(

        <section className="col-md-12 col-lg-12 bg-section">
            <div className="container bg-gracias">
                <div className="container">
                    <img src={gracias} alt="gracias" />
                    <h1>¡Gracias por tu compra!</h1>
                    <p>Revisa tu correo para visualizar el detalle de tu compra.</p>
                    <Link to={'/'}>Seguir Comprando</Link>
                </div>
            </div>
        </section>

        )
    }
}