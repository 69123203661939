import React, {Component} from 'react';
import Footer from './Footer'
import {Sugar} from 'react-preloaders';

export default class ServicioCliente extends Component{

    constructor(props) {
        super(props);
        
        this.state = { 
            loading: false
        };

        
    }

    render(){
        return(

        <section className="col-md-12 col-lg-12 bg-section">
            <div className="container">
           <div class="row">
                <div class="col-lg-12 cb-serviciocliente">
                    <h1>Servicio al cliente</h1>
                    <ul>
                        <li>Servicio al cliente
                            <p>Nuestro personal de servicio al cliente está disponible de lunes a Viernes de 10 AM a 7 PM  y Sábados de 10 AM – 6 PM. Si desea realizar un nuevo pedido, tiene una consulta con respecto a una orden existente o desea obtener más información sobre nuestros productos, puede ponerse en contacto con nosotros por correo electrónico a contacto@dolcesalato.com.pe o llame al +51 930 303 295</p>
                        </li>
                        <li>Ordenando
                            <p>Los pedidos de tortas, cupcakes y afines, pueden ser realizados en nuestra tienda virtual, por correo electrónico, o redes sociales o teléfono. Puede encontrar información sobre las opciones de personalización, los pedidos mínimos y los plazos de entrega en la sección de descripción del producto correspondiente en nuestra tienda en línea. Alternativamente, puede llamar a nuestra taller +51 (1) +51 930 303 295 o enviar un correo electrónico a contacto@dolcesalato.com.pe</p>
                        </li>
                        <li>Haga Clic y Delivery
                            <p>Contamos con Delivery para algunos de nuestros productos. Las entregas son directamente a domicilio. lunes a Viernes de 10 AM a 7 PM  y Sábados de 10 AM – 6 PM (consulte horario de días festivos).</p>
                            <p>Una vez entregado, la responsabilidad de cualquier daño a través del transporte recae en el cliente.</p>
                        </li>
                        <li>Entrega y Envío
                            <p>Contamos con un rango de Delivery, de 10 AM – 6 PM. No contamos con horario fijo. Los pedidos son entregados en función a la ruta de nuestro Courrier. En caso el cliente desee que su pedido llegue en hora exacto, el delivery tendrá costo diferente.</p>
                            <p>Proporcione instrucciones claras de entrega y un número de contacto para nuestro conductor de entrega. Si, por alguna razón, la entrega no puede ser aceptada en el momento y lugar acordados, nuestro conductor le devolverá su pedido y lo almacenaremos solo por las próximas 24 horas, durante las cuales usted puede llamarnos para solicitar la re-entrega con un pago adicional. El costo de esta nueva entrega recae exclusivamente en el cliente.</p>
                        </li>
                        <li>Recojo
                            <p>Tortas, cupcakes y demás pedidos se pueden recoger de nuestro local comercial. El horario de atención es de lunes a sábado de 10 AM a 7 PM (consulte horario de días festivos).</p>
                            <p>Los clientes deben firmar una nota de descargo de responsabilidad para confirmar que su pedido ha sido recibido en buenas condiciones. Una vez recogido, la responsabilidad de cualquier daño a través del transporte recae en el cliente.</p>
                            <p>Si por alguna razón no se recoge un pedido a tiempo, lo almacenaremos durante 24 horas para que tenga tiempo suficiente para llamar a nuestra oficina de atención al cliente al +51 930 303 295</p>
                            <p>y volver a coordinar un nuevo horario de recojo. Tenga en cuenta que no ofrecemos devoluciones o reemplazos para órdenes que no se han recogido dentro de las 24 horas posteriores a la fecha y hora acordadas.</p>
                        </li>
                        <li>Los cambios a los pedidos actuales
                            <p>Si ha detectado un error en la confirmación de su pedido o desea hacer cambios en una orden existente, notifíquelo hasta 72 horas antes de la entrega.</p>
                            <p>Tenga en cuenta que cualquier cambio en su orden actual puede ser cargado. Por lo tanto, recomendamos que los clientes siempre revisen los detalles del pedido, como fechas, tamaño de la torta y mensajes personales, antes de confirmar su pedido, ya que no podemos ofrecer reembolsos o reemplazos si se ha dado información incorrecta.</p>
                        </li>
                        <li>Reembolsos y Política de Devoluciones
                            <p>Siempre haremos todo lo posible para preparar, empaquetar y entregar sus productos con el máximo cuidado. Sin embargo, los accidentes pueden ocurrir y por eso le pedimos que compruebe que su pedido este en óptimas condiciones antes de firmar su nota de entrega / descargo de responsabilidad. En el caso improbable de que encuentre que su pedido es incorrecto, incompleto o dañado al momento de la entrega, por favor informe a nuestro servicio al cliente inmediatamente al +51 930 303 295 y haremos todo lo posible para solucionar el incoveniente.</p>
                            <p>Lamentablemente nuestra política de empresa no acepta reembolsos. Sin embargo, damos la opción que el cliente solicite otro pedido y pedidos por el mismo monto que ha pagado.</p>
                        </li>
                        <li>Información del Producto<br/>
                            Productos recién horneados
                            <p>En Dolce Slato todas nuestras tortas, cupcakes y productos afines ofrecidos, son recién horneados y elaborados a mano, cada día. Como cada artículo es tan individual como la persona que lo hizo, pueden producirse mínimas variaciones de la imagen original del sitio web.</p>
                        </li>
                        <li>Manejo
                            <p>Al recoger su torta, por favor lleve su producto con el mayor cuidado posible. Las cajas deben mantenerse erguidas y niveladas en todo momento para evitar que las decoraciones se caigan de la torta o que un cupcake se deteriore. Por favor, abstenerse de tener más de una caja en una mano, ya que esto hará que las tortas se inclinan y se deslizan hacia el lado de la caja de la torta. Dolce Salato no será responsable de los artículos dañados una vez que han sido entregados al cliente. Después de dejar el local, por favor mantenga sus tortas a temperatura ambiente y manténgalas alejadas de la luz directa del sol y fuentes de calor. Si se transporta en auto, colóquelas en una superficie nivelada y antideslizante y evite las carreteras con baches.
                            </p>
                        </li>
                        <li>Almacenamiento y Vida Útil
                            <p>A menos que se indique lo contrario, nuestras tortas y cupcakes deben almacenarse en la refrigeradora solo cuando la empresa lo sugiriera, para que puedan lograr la siguiente vida útil a partir del día siguiente o recojo, recuerde que esto solo depende de Usted:</p>
                            <p>Cupcakes:           24 horas<br/>
                                Tortas:                 24 horas<br/>
                                Jar Cakes:           24 horas<br/>
                                Naked Cake:       24 horas<br/>
                                Postres:               24 horas
                            </p>
                            <p>Las tortas y los cupcakes con adornos de azúcar deben ser recogidas tan cerca del evento requerido como sea posible. Las decoraciones de azúcar pueden derretirse o derrumbarse en condiciones frías y húmedas, es por ello que no deben refrigerarse.</p>
                        </li>
                        <li>Información dietética y alérgenos
                            <p>Todos nuestros productos están hechos en una cocina que maneja nueces, gluten, productos lácteos, huevo y soya. Si usted es o presenta alergias a algunos productos mencionados ponerse en contacto con nuestro equipo de ventas en +51 930 303 295</p>
                            <p>A pesar de ello Dolce Salato cuenta con queques light y dieteticos que puede reducir los riesgos.</p>
                        </li>
                        <li>INFORMACIÓN DE ENTREGA Y ENVÍOS<br/>
                            Envío de Tortas
                            <p>En Dolce Salato utilizamos un servicio de delivery que está disponible de lunes a sábado (consultar los días festivos) entre las horas de de 10 AM – 6 PM. Entregamos a la mayoría de los distritos de Lima, pero por favor verifique para asegurarse de que su dirección esté cubierta. En caso tengan duda respecto a la cobertura por favor, comuníquese con nuestra oficina de Atención al Cliente al +51 930 303 295</p>
                            <p>Al realizar un pedido para delivery, asegúrese de proporcionar un número de teléfono en el que nuestro conductor puede ponerse en contacto con usted o el destinatario, en caso de haber problemas en la entrega de la orden. Si por alguna razón nadie está presente para aceptar la entrega en la dirección brindada por usted, nosotros tomaremos su pedido de vuelta a Dolce Salato y la conservaremos por 24 horas. Durante este tiempo puede llamar a nuestra local al +51 930 303 295 para solicitar una nueva entrega con un cargo adicional. Por favor, lea nuestros Términos y Condiciones para más información.</p>
                        </li>
                        <li>Productos Ordenados por nuestro Sitio Web
                            <p>Dolce Salato utiliza un servicio de delivery estándar para todos los pedidos en línea dentro de Lima. El tiempo estimado para la preparación de un pedido dependerá de la complejidad de la torta. El plazo mínimo para la reserva de tortas temáticas es de 5 a 7 días. Nos reservamos el derecho de no enviar ningún artículo frágil fuera de Lima si el producto se considera en riesgo de daños durante el transporte.</p>
                            <p>Los bienes le serán entregados en la dirección que proporcionó durante el proceso de la orden, que puede ser una dirección distinta a la dirección de facturación, pero tenga en cuenta que puede ser necesaria documentación adicional para cumplir con dichos pedidos. No seremos responsables de ningún retraso en la entrega de los bienes, sin importar lo causado.</p>
                            <p>Los retrasos de entrega causados por terceros y no por Dolce Salato pueden ser discutidos directamente con nosotros para solucionar el problema lo más rápido posible. Para ello, pedimos que se comuniquen a nuestra oficina de Atención al Cliente al +51 930 303 295</p>
                        </li>
                    </ul>
                </div>
            </div>
            <Sugar color= "#ffc107" customLoading={this.state.loading} />
            </div>
            <Footer />
        </section>
        )
    }
}