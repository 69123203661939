import React, {Component} from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Common from "./Common";
import axios from "axios";
import {Sugar} from 'react-preloaders';
import ClipLoader from "react-spinners/ClipLoader";

import carrito from "../images/servicios/snack.jpg"

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class CoffeeBreak extends Component{

    constructor(props) {
        super(props);

        this.state = { 
            startDate: new Date(),
            nombres : '',
            celular: '',
            email: '',
            tipoEvento: '',
            lugarEvento: '',
            numeroPersonas: '',
            descripcionAdicional: '',
            selectedValue : '',
            loading :  false
        };

        this.optionsEvento = [
            {value: '1', label: 'Corporativo'},
            {value: '2', label: 'Infantil'},
            {value: '3', label: 'Otros'},
        ]

        this.handleEvento = this.handleEvento.bind(this);
    }
      
    setField (e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleEvento(selectedOption) {

        this.setState({ selectedValue : selectedOption.value, tipoEvento: selectedOption.label});
    }

    handleChange = date => {
        this.setState({
          startDate: date
        });
    };

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    handleClick(event){
        event.preventDefault();
        
        const {nombres, celular, email, tipoEvento, lugarEvento, numeroPersonas, startDate, descripcionAdicional} = this.state;
        
        if(nombres !== '' && celular !== 0 && email !== '' && tipoEvento !== '' && lugarEvento !== '' && numeroPersonas !== ''){

            this.setState({ loading : true });

            if(celular.length === 9 && /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){

                this.JsonSnack = {
                    'nombres' : nombres,
                    'celular' : celular,
                    'email' : email,
                    'tipoEvento' : tipoEvento,
                    'lugarEvento' : lugarEvento,
                    'numeroPersonas' : numeroPersonas,
                    'fechaEvento' : this.convert(startDate),
                    'descripcionAdicional' : descripcionAdicional
                }
        
                axios.post(`${Common.urlAPI}/EnviarCorreo/Snack`, this.JsonSnack , config)
                        .then((response) => {

                            const { history } = this.props
                            history.push('/graciasServiciosAdicionales')
                        }).catch((error) =>{
                            
                            this.setState({ loading : false });
                            Common.notify("Ocurrio un error vuelve a intentarlo","error");
                        })

            }else{
                Common.notify("Ingresar el formato correcto","error");
            }
        }else{
            Common.notify("Llenar los campos solicitados", "error")
        }
       
    }

    render(){
        return(
            <section className="col-md-12 col-lg-12">
                <div className="container">
                <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+carrito+")", backgroundSize : "cover"}}>
                          <h4>Snack Cart</h4>
                          <div className="row">
                              <div className="col-md-12 frm-serviciosa">
                                  <form>
                                      <div className="form-group">
                                          <label>Nombre o Empresa</label>
                                          <input type="text" className="form-control" placeholder="Nombres o Empresa" name="nombres" onChange={(e)=>this.setField(e)}/>
                                      </div>
                                      <div className="form-group">
                                          <label>Número celular</label>
                                          <input type="number" className="form-control" placeholder="Número celular" name="celular" onChange={(e)=>this.setField(e)}/>
                                      </div>
                                      <div className="form-group">
                                          <label>Correo electrónico</label>
                                          <input type="text" className="form-control" placeholder="Correo electrónico" name="email" onChange={(e)=>this.setField(e)}/>
                                      </div>
                                      <div className="form-group">
                                          <label>Tipo Evento</label>
                                          <Select
                                          value={this.optionsEvento.filter(({value}) => value === this.state.selectedValue)}
                                          onChange={this.handleEvento}
                                          options = {this.optionsEvento}
                                          placeholder = {'Seleccionar'}
                                          className="select" />
                                      </div>
                                      <div className="form-group">
                                          <label>Lugar del Evento</label>
                                          <input type="text" className="form-control" placeholder="Lugar del Evento" name="lugarEvento" onChange={(e)=>this.setField(e)}/>
                                      </div>
                                      <div className="form-group">
                                          <label>Número Invitados</label>
                                          <input type="number" className="form-control" placeholder="Número Invitados" name="numeroPersonas" onChange={(e)=>this.setField(e)}/>
                                      </div>
                                      <div className="form-group">
                                          <label>Fecha del Evento</label><br/>
                                          <DatePicker 
                                              className="form-control calendar"
                                              selected={this.state.startDate}
                                              onChange={this.handleChange}
                                              dateFormat="d/MM/yyyy"
                                          />
                                      </div>
                                      <div className="form-group">
                                          <label>Descripción Adicional</label>
                                          <textarea className="form-control"  rows="3" name="descripcionAdicional" onChange={(e)=>this.setField(e)}></textarea>
                                      </div>
                                      <br />
                                      {
                                        this.state.loading ? <button disabled={true} style={{opacity : "0.5", cursor : "default"}} className="sc-htpNat btn-saenviar" onClick={(e) => this.handleClick(e)}>ENVIAR&nbsp;&nbsp;<ClipLoader size={25} loading={this.state.loading} /></button>
                                        
                                        : <button className="sc-htpNat btn-saenviar" onClick={(e) => this.handleClick(e)}>ENVIAR</button>
                                      }
                                      
                                  </form>
                              </div>
                          </div>
                      </div>
                </div>
                <Sugar color= "#ffc107" customLoading={this.state.loading} />
            </section>
        )
    }
    
}
