import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import gracias from '../images/gracias.png'

export default class GraciasServiciosAdicionales extends Component{
    render(){
        return(
        <section className="col-md-12 col-lg-12 bg-section">
            <div className="container bg-gracias">
                <div className="container">
                    <img src={gracias} alt="gracias" />
                    <h1>¡Gracias por enviar tu solicitud!</h1>
                    <p>Nos pondrémos en contacto contigo lo más pronto posible.</p>
                    <Link to={'/'}>Volver a inicio</Link>
                </div>
            </div>
        </section>
        )
    }
}