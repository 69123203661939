import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../src/menuefects.js'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const tagManagerArgs = {
  gtmId: 'GTM-5ZBSNT8',
  events: {
    sendUserInfo: 'userInfo'
  }
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

  serviceWorker.unregister();
 