import $ from 'jquery';

var contador = 1;
var contador2 = 1;
var contador3 = 1;

$(document).ready(function() {

	$("#lista").mouseenter(function() {
		$("#desplegar").show();
	});
					
	$("#lista").mouseleave(function() {
		$("#desplegar").hide();
	});
	
	$("#servicios").mouseenter(function() {
		$("#desplegar-s2").show();
	});
					
	$("#servicios").mouseleave(function() {
		$("#desplegar-s2").hide();
    });
    
	/*$(".up-cant").click(function(){
		$("#")
	});*/
    

	//POSTRES Y TORTAS
	$("#PostresTortas").mouseenter(function() {
		$("#desplegar2").hide();
	});

	$("#PostresTortas").mouseenter(function() {
		$("#desplegar3").hide();
	});

	$("#PostresTortas").mouseenter(function() {
		$("#desplegar4").hide();
	});
	//BOCADITOS DULCES
	
	$("#navbarDropdownSubCateg").mouseenter(function() {
		$("#desplegar2").show();
	});

	
	$("#desplegar2").mouseleave(function() {
		$("#desplegar2").hide();
	});

	$("#navbarDropdownSubCateg").mouseenter(function() {
		$("#desplegar3").hide();
	});
	
	$("#navbarDropdownSubCateg").mouseenter(function() {
		$("#desplegar4").hide();
	});

	//CUPCAKES
	
	$("#lista3").mouseenter(function() {
		$("#desplegar3").show();
	});

	$("#desplegar3").mouseleave(function() {
		$("#desplegar3").hide();
	});
	
	$("#lista3").mouseenter(function() {
		$("#desplegar2").hide();
	});
	
	$("#lista3").mouseenter(function() {
		$("#desplegar4").hide();
	});

	//NACKED

	$("#naked").mouseenter(function() {
		$("#desplegar2").hide();
	});

	$("#naked").mouseenter(function() {
		$("#desplegar3").hide();
	});

	$("#naked").mouseenter(function() {
		$("#desplegar4").hide();
	});

	//JAR

	$("#lista4").mouseenter(function() {
		$("#desplegar4").show();
	});

	$("#desplegar4").mouseleave(function() {
		$("#desplegar4").hide();
	});

	$("#lista4").mouseenter(function() {
		$("#desplegar2").hide();
	});

	$("#lista4").mouseenter(function() {
		$("#desplegar3").hide();
	});

	//COMBOS
	
	$("#combos").mouseenter(function() {
		$("#desplegar2").hide();
	});

	$("#combos").mouseenter(function() {
		$("#desplegar3").hide();
	});

	$("#combos").mouseenter(function() {
		$("#desplegar4").hide();
	});

	//TENDENCIAS

	$("#tendencias").mouseenter(function() {
		$("#desplegar2").hide();
	});

	$("#tendencias").mouseenter(function() {
		$("#desplegar3").hide();
	});

	$("#tendencias").mouseenter(function() {
		$("#desplegar4").hide();
	});


	$('.icono-menu').click(function(){
		if (contador == 1) {
			$('.aside-menu').css(
				'transform', 'translate(0vw)'
			);
			contador = 0;
		} else {
			contador = 1;
			$('.aside-menu').css(
				'transform', 'translate(-100vw)'
			);
		}
	});

	$('.mostrarbuscador').click(function(){
		if (contador2 == 1) {
			$('.contenedor-gn').css(
				'margin-top', '0px'
			);
			contador2 = 0;
		} else {
			contador2 = 1;
			$('.contenedor-gn').css(
				'margin-top', '56px'
			);
		}
	});

	$('.menuasidem').click(function(){
		if (contador3 == 1) {
			$('.aside').css(
				'display', 'block'
			);
			$('.aside-menu').css(
				'transform', 'translate(-100vw)'
			);
			contador3 = 0;
		} else {
			contador3 = 1;
			$('.aside').css(
				'display', 'none'
			);
		}
	});

	
});

