import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import Common from './Common';
import Footer from './Footer'

const config = {
    headers: { Authorization: Common.tokenAPI}
};

var data = [];

export default class Login extends Component{

    constructor(props) {
        super(props);
        
        this.state = { 
            email: null,
            contrasenia: null,
            sesion : localStorage.getItem('sesion')
        };

        
    }

    componentDidMount(){

        
        if(this.state.sesion !== null){
            
            window.location.href = Common.urlRedirect;
        }
    }

    setField (e) {

        this.setState({[e.target.name]: e.target.value});
    }



    Login(e){

        e.preventDefault();

        let email = this.state.email;
        let contrasenia = this.state.contrasenia;

        axios.get(`${Common.urlAPI}/Persona/Login?email=${email}&contrasenia=${contrasenia}`,config)
            .then((response) => {

                if(response.data.detail.length > 0 ){
                    response.data.detail.map(item => {

                        data.push({
                        'userId' : item.userId,
                        'email' : item.email
                        })
                    });
    
    
                    localStorage.setItem('email', data[0].email);
                    localStorage.setItem('id', data[0].userId);
                    
                    if(response.data.success === true)
                    {
                        localStorage.setItem('sesion',1);
    
                        window.location.href = Common.urlRedirect
                    }
                }
                else{
                    Common.notify('Usuario o contraseña incorrectos', 'error')
                }

            })
            .catch(error => {
                if (error.response.status === 400) {
                    Common.notify('Sucedió un error', 'error')
                } else {
                    Common.notifyValidation(error.response.data)
                }

        });

    }


    render(){
        return(
            <div className="container-fluid bordes-ds">
            <section className="col-md-12 col-lg-12 col-xl-12">
            <div className="row login-responsive">
                <div className="col-md-4 mx-auto">
                <div id="first">
                    <div className="myform form ">
                        <div className="logo mb-3">
                            <div className="col-md-12 text-center">
                                <h1>Iniciar Sesión</h1>
                            </div>
                        </div>
                        <form>
                            <div className="form-group">
                                <label>Correo Electrónico</label>
                                <input type="email" name="email"  className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="form-group">
                                <label>Contraseña</label><Link  to={'/recuperarContrasenia'} className="olct">¿Olvidaste tu contraseña?</Link>
                                <input type="password" name="contrasenia" className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="form-group">
                                <p className="text-center text-tc">Al registrarte aceptas nuestros <a href="/terminosCondiciones">Términos y Condiciones</a></p>
                            </div>
                            <div className="col-md-12 text-center ">
                                <button  className=" btn btn-block mybtn btn-dolcesalato tx-tfm" onClick={(e) =>this.Login(e)}>INICIAR SESIÓN</button>
                            </div>
                            <div className="col-md-12 ">
                                <div className="login-or">
                                    <hr className="hr-or" />
                                    <span className="span-or">ó</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <p className="text-center">¿No tienes cuenta? <Link to={'/registro'} history={this.props.history} id="signup"> Registrate aquí</Link></p>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </section>
        </div>
         
        )
    }
}