import React, {Component} from 'react';
import Select from 'react-select';
import Common from "./Common";
import axios from "axios";
import Footer from './Footer'
import {Sugar} from 'react-preloaders';

import box from '../images/sport.png'


const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class Cobertura extends Component{

    constructor(props) {
        super(props);
        

        this.state = { 
            dataDistrito: [],
            selectedValue: '',
            precio: 0,
            loading: true
        };

        this.handleChange = this.handleChange.bind(this);

        axios.get(`${Common.urlAPI}/Distrito`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.distritoId,
                    label: item.nombre,
                };
            });

            this.setState({dataDistrito : source, loading :  false});
        })

    }
    

    handleChange(selectedOption) {

        this.setState({selectedValue: selectedOption.value});
    }

    handlePrecio(e){
        e.preventDefault();

        console.log(this.state.selectedValue);

        axios.get(`${Common.urlAPI}/Distrito/Precio/${this.state.selectedValue}`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    precio : item.costo
                    
                };
            });

            this.setState({precio : source[0].precio});
        })
    }

    render(){
        return(
             <section className="col-md-12">
                 <div className="container bg-cobertura">
                    <div className="card">
                        <h5 className="card-header">Nuestra Cobertura</h5>
                        <div className="card-body">
                            <h5 className="card-title">Consulta el precio de envío</h5>
                            <div className="row">
                                <div className="col-md-3">
                                    <Select
                                       
                                        value={this.state.dataDistrito.filter(({value}) => value === this.state.selectedValue)}
                                        onChange={this.handleChange}
                                        options={this.state.dataDistrito}
                                        placeholder={'Seleccione Distrito'}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <button className="btn btn-dolcesalato" onClick={e => this.handlePrecio(e)}>Consulta</button>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <h1 className="tp-cobertura-precio">
                                        S/ {this.state.precio}.00
                                    </h1>
                                </div>
                                <div className="col-md-5">
                                   <img src={box} alt="box" height="400" width="600" />
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
                 <Sugar color= "#ffc107" customLoading={this.state.loading} />
                 <Footer />
             </section>
        )
    }
}