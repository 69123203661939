import React, {Component} from 'react';
import Common from "./Common";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from './Footer'
import {Sugar} from 'react-preloaders';


const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class LibroReclamaciones extends Component{

    constructor(props) {
        super(props);

        this.state = { 
            startDate: new Date(),
            nombres: '',
            celular: '',
            email: '',
            nroDni: '',
            nroPedido: '',
            detalleReclamo: '',
            loading: false
        };
    }

    setField (e) {
        this.setState({[e.target.name]: e.target.value});
    }


    handleChange = date => {
        this.setState({
          startDate: date
        });
    };

    handleClick(event){
        event.preventDefault();
        
        const {nombres, celular, email, nroDni, nroPedido, detalleReclamo} = this.state;
        
        if(nombres !== '' && email !== '' && nroDni !== 0 && nroPedido !== 0 && detalleReclamo !== ''){

             if(celular.length === 9 && /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email) && nroDni.length === 8){

                this.setState({ loading : true });

                this.JsonReclamo = {
                    'nombres' : nombres,
                    'celular': celular,
                    'email' : email,
                    'nroDni' : nroDni,
                    'nroPedido' : nroPedido,
                    'detalleReclamo' : detalleReclamo
                }
        
                axios.post(`${Common.urlAPI}/EnviarCorreo/Reclamo`, this.JsonReclamo , config)
                        .then((response) => {
                              
                            const { history } = this.props
                            history.push('/graciasReclamo')
                        })

                        
             }else{
                Common.notify('Ingresar el formato correcto', 'error')
             }
        }else{
            Common.notify('Rellenar todos los campos requeridos', 'error')
        }
        
     }

    render(){
        return(
            <section className="col-md-12 col-lg-12">
                <div className="container">
                <div className="cb-reclamos" id="bodyServicios" style={{backgroundColor: "#fffff", border: "2px solid #f1f1f1", marginBottom: "100px"}}>
                           <h4>Libro Reclamaciones</h4>
                           <div className="row">
                               <div className="col-md-12 frm-serviciosreclamo">
                                   <form>
                                       <div className="form-group">
                                           <label>Nombres Completos</label>
                                           <input type="text" className="form-control" placeholder="Nombres Completos" name="nombres" onChange={(e)=>this.setField(e)} required/>
                                       </div>
                                       <div className="form-group">
                                           <label>Celular</label>
                                           <input type="number" className="form-control" placeholder="Celular" name="celular" onChange={(e)=>this.setField(e)} required/>
                                       </div>
                                       <div className="form-group">
                                           <label>Correo electrónico</label>
                                           <input type="text" className="form-control" placeholder="Correo electrónico" name="email" onChange={(e)=>this.setField(e)} required/>
                                       </div>
                                       <div className="form-group">
                                           <label>Nro. de DNI</label>
                                           <input type="number" className="form-control" placeholder="Nro. de DNI" name="nroDni" onChange={(e)=>this.setField(e)} required/>
                                       </div>
                                       <div className="form-group">
                                           <label>Nro. de Pedido</label>
                                           <input type="text" className="form-control" placeholder="Nro. de Pedido" name="nroPedido" onChange={(e)=>this.setField(e)} required/>
                                       </div>
                                       <div className="form-group">
                                           <label>Detalle del reclamo</label>
                                           <textarea className="form-control"  rows="3" name="detalleReclamo" onChange={(e)=>this.setField(e)} required></textarea>
                                       </div>
                                       <br />
                                       {
                                            this.state.loading ? <button disabled={true} style={{opacity : "0.5", cursor : "default"}}  className="sc-htpNat btn-saenviar" onClick={(e) => this.handleClick(e)}>ENVIAR&nbsp;&nbsp;<ClipLoader size={25} loading={this.state.loading} /></button>
                                            
                                            : <button className="sc-htpNat btn-saenviar" onClick={(e) => this.handleClick(e)}>ENVIAR</button>
                                        }
                                       
                                   </form>
                               </div>
                           </div>
                       </div>
                       <Sugar color= "#ffc107" customLoading={this.state.loading} />
                </div>
                <Footer />
            </section>
        )
    }
    
}
