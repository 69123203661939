import React, { Component } from 'react';
import Common from "./Common";
import axios from "axios";
import Footer from './Footer'
import Aside from './Aside';
import Select from 'react-select';
import {Sugar} from 'react-preloaders';

const config = {
  headers: { Authorization: Common.tokenAPI}
};

export default class DetalleProducto extends Component {

  constructor(props) {
    super(props);
    
    const productoId = props.location.state.productoId;

    this.state = { 
      dataProducto: [],
      productoIdP : productoId,
      optionsAcabado : [],
      optionsPorciones : [],
      optionsSabor : [],
      optionsRelleno : [],
      optionsPorcionesSAcabado : [],
      estado : 0,
      productoId : '',
      varDisplay: '',
      estadoCesta : false,
      displayPorciones : true,
      displaySabor : true,
      displayRelleno : true,
      selectedValueAcabado: '',
      selectedValuePorciones : '',
      selectedValueSabor : '',
      value : '',
      acabadoId : '',
      precioPorcion : 0,
      precioSabor: 0,
      precioTotal : 0,
      porciones : 0,
      count : 0,
      productoDetalleId : '',
      clasificacion : '',
      cantidad : '',
      saborId: '',
      rellenoId1: '',
      rellenoId2: '',
      loading : true,
      medida : '',
      categoria : '',
      displayAside : "none",
      dataProductoCarrito : JSON.parse(localStorage.getItem('dataProducto')),
      cantidadProducto : 0
    };

    

    axios.get(`${Common.urlAPI}/Producto/DetalleProducto/${productoId}`,config).then((response) => {

      let source = response.data.detail.map(item => {
          return {
              productoId: item.productoId,
              productoDetalleId :  item.productoDetalleId,
              nombre: item.nombre,
              imagen : item.imagen,
              descripcion : item.descripcion,
              clasificacion : item.clasificacion,
              medida : item.medida,
              categoriaId : item.categoriaId,
              cantidad : item.cantidad,
              precio : item.precio,
              diasEntrega : item.diasEntrega,
              acabadoId : item.acabadoId
          };
      });


      this.setState({dataProducto : source, loading : false});
  })

  axios.get(`${Common.urlAPI}/Acabado/Listar/${productoId}`,config).then((response) => {

    let source = response.data.detail.map(item => {
        return {
            value: item.acabadoId,
            label: item.nombre
            
        };
    });

    if(source.length !== 0){

      this.setState({optionsAcabado : source, selectedValueAcabado : source[0].value, displayPorciones : false});

   
      this.handleAcabado(source[0]);
    }
     
  })

  axios.get(`${Common.urlAPI}/Porciones/Listar?ProductoId=${productoId}&AcabadoId=${0}`,config).then((response) => {

    let source = response.data.detail.map(item => {
        return {
            value: item.detalleProductoId,
            label: item.cantidad,
           
        };
    });

    if(source.length !== 0){

      this.setState({optionsPorcionesSAcabado : source, selectedValuePorciones : source[0].value});

      this.handlePorcionesSinAcabado(source[0]);

    }
    
   })
  
  this.handleAcabado = this.handleAcabado.bind(this);
  this.handlePorciones = this.handlePorciones.bind(this);
  this.handleSabor = this.handleSabor.bind(this);
  this.handleRelleno = this.handleRelleno.bind(this);
  this.handlePorcionesSinAcabado = this.handlePorcionesSinAcabado.bind(this);
}

  handleAcabado(selectedOption) {


    this.setState({ selectedValueAcabado : selectedOption.value, displayPorciones : false, displaySabor: false, displayRelleno: false});
    
    axios.get(`${Common.urlAPI}/Porciones/Listar?ProductoId=${this.state.productoIdP}&AcabadoId=${selectedOption.value}`,config).then((response) => {

      let source = response.data.detail.map(item => {
          return {
              value: item.detalleProductoId,
              label: item.cantidad,
             
          };
      });

      this.setState({optionsPorciones : source, selectedValuePorciones: source[0].value, acabadoId : selectedOption.value, precioTotal: 0, porciones: 0});

      this.handlePorciones(source[0]);

     })

  }

  handlePorciones(selectedOption) {


    this.setState({ selectedValuePorciones : selectedOption.value, porciones : selectedOption.label, productoDetalleId : selectedOption.value, cantidad : selectedOption.label, productoId: 0});
    
    axios.get(`${Common.urlAPI}/Porciones/ConsultarPrecio?ProductoDetalleId=${selectedOption.value}&AcabadoId=${this.state.acabadoId}`,config).then((response) => {

      let source = response.data.detail.map(item => {
          return {
              precio: item.precio
             
          };
      });

      let precioSuma = parseInt(source[0].precio) + parseInt(this.state.precioSabor)

      this.setState({ precioTotal : precioSuma, precioPorcion: source[0].precio });

     })

     axios.get(`${Common.urlAPI}/Sabor`,config).then((response) => {

      let source = response.data.detail.map(item => {
          return {
              value: item.saborId,
              label: item.nombre
              
          };
      });

      this.setState({optionsSabor : source});
    })

    axios.get(`${Common.urlAPI}/Relleno`,config).then((response) => {

      let source = response.data.detail.map(item => {
          return {
              value: item.rellenoId,
              label: item.nombre
              
          };
      });

      this.setState({optionsRelleno : source});
    })

  }

  handlePorcionesSinAcabado(selectedOption) {


    this.setState({ selectedValuePorciones : selectedOption.value, porciones : selectedOption.label, displaySabor: false, displayRelleno: false, productoDetalleId : selectedOption.value, cantidad : selectedOption.label, productoId: 0});
    
    axios.get(`${Common.urlAPI}/Porciones/ConsultarPrecio?ProductoDetalleId=${selectedOption.value}&AcabadoId=${'0'}`,config).then((response) => {

      let source = response.data.detail.map(item => {
          return {
              precio: item.precio
             
          };
      });

      let precioSuma = parseInt(source[0].precio) + parseInt(this.state.precioSabor)

      this.setState({ precioTotal : precioSuma, precioPorcion: source[0].precio });

     })

     axios.get(`${Common.urlAPI}/Sabor`,config).then((response) => {

      let source = response.data.detail.map(item => {
          return {
              value: item.saborId,
              label: item.nombre
              
          };
      });

      this.setState({optionsSabor : source});
    })

    axios.get(`${Common.urlAPI}/Relleno`,config).then((response) => {

      let source = response.data.detail.map(item => {
          return {
              value: item.rellenoId,
              label: item.nombre
              
          };
      });

      this.setState({optionsRelleno : source});
    })

  }


  handleSabor(selectedOption) {

    this.setState({ selectedValueSabor : selectedOption.value, saborId : selectedOption.value});
    
    axios.get(`${Common.urlAPI}/Sabor/ListarPrecio/${selectedOption.value}`,config).then((response) => {

      let precioSabor = response.data.detail[0].precio;

      let precioSuma = parseInt(this.state.precioPorcion) + parseInt(precioSabor)
       
      this.setState({precioTotal : precioSuma, precioSabor : precioSabor});
    })

  }

  handleRelleno(value) {


    if(value !== null || value === '')
    {
      if(value.length <= 2){

        this.setState({value});

      }
      else{
        Common.notify("Maxímo 2 sabores", "error")
      }
    }else{
      this.setState({value});
    }


    if(value !== null)
    {
      if(value.length === 1){
        this.setState({rellenoId1 : value[0].value})
      }
  
      if(value.length === 2){
        this.setState({rellenoId1 : value[0].value, rellenoId2: value[1].value})
      }
    }else{
      this.setState({rellenoId1 : '', rellenoId2: ''})
    }
   


  }

  componentDidMount () {

  }

  AddProducto(e,productoId, clasificacion, medida, categoria){
        
    console.log(categoria);

    e.preventDefault();
    var newCount = this.state.count + 1 
    this.setState({estado : 1, productoId : productoId, clasificacion: clasificacion, varDisplay: 'none', estadoCesta : true, count : newCount, medida : medida, categoria : categoria});
  }

  mostarAside(e){
    e.preventDefault();

    this.setState({displayAside: "block"})
  }

  contadorProductos = (cantidad) =>{
    this.setState({cantidadProducto : cantidad})
 }

  render() {
    return (
      <div className="container-fluid bordes-ds">
        <section className="col-md-9 col-lg-9 col-xl-9">
        <div className="detallePro">
          {
            this.state.dataProducto.map(item =>{

              return(
                
                (item.clasificacion === 1)?
                <div className="row">
                  <div className="col-lg-12 detalle">
                    <div className="row">
                      <div className="col-lg-6 dp-img">
                        <figure>
                          <img src={Common.urlIMG+item.imagen} alt="" className="img-adaptable" />
                        </figure>
                      </div>
                      <div className="col-lg-6 dp-datos">
                        <h4 style={{ marginBottom : "8px"}} className="sc-ifAKCX iGWcpD">
                          {item.nombre}
                        </h4>
                        <p className='card-text' id='cantidadPrincipal'>
                          {item.cantidad} Unidad
                        </p>
                        <div style={{ visibility: "visible", marginBottom : "64px"}} className="sc-gisBJw ippVNs">
                          <div className="sc-kjoXOD gjthul">
                            <span color="error" className="sc-ifAKCX gmaHPm">S/ {item.precio}</span>
                          </div>
                        </div>
                        <div className="sc-1p5q16i-0 fnhIML">
                        <button color="secondary" className="sc-1p5q16i-2 Nmvat sc-htpNat gEKfxI" onClick={(e)=>this.AddProducto(e, item.productoId)}>AGREGAR</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 descripcion">
                    <h2 className="descripcionTitulo">Acerca del producto</h2>
                    <span className="wysiwyg sc-ifAKCX drzNke">
                      {item.descripcion}
                    </span>
                  </div>
                </div>

                :

                (item.acabadoId === '1' || item.acabadoId === '2' || item.acabadoId === '3')?

                <div className="row">
                  <div className="col-lg-12 detalle">
                    <div className="row">
                      <div className="col-lg-6 dp-img">
                        <figure>
                          <img src={Common.urlIMG+item.imagen} alt="" className="img-adaptable" />
                        </figure>
                      </div>
                      <div className="col-lg-6 dp-datos">
                        <h4 style={{ marginBottom : "8px"}} className="sc-ifAKCX iGWcpD">
                          {item.nombre}
                        </h4>
                        <p className='card-text' id='cantidadPrincipal'>
                          {this.state.porciones} Porciones
                        </p>
                        <div style={{ visibility: "visible", marginBottom : "20px"}} className="sc-gisBJw ippVNs">
                          <div className="sc-kjoXOD gjthul">
                            <span color="error" className="sc-ifAKCX gmaHPm">S/ {this.state.precioTotal}.00</span>
                          </div>
                        </div>
                        <div className="container-fluid">
                          <div className="row">
                           <div className="col-md-6">
                              <label>Acabado</label>
                              <Select
                                value={this.state.optionsAcabado.filter(({value}) => value === this.state.selectedValueAcabado)}
                                onChange={this.handleAcabado}
                                options={this.state.optionsAcabado}
                                placeholder = {'Seleccionar'}/>
                           </div>
                           <div className="col-md-6">
                              <label>Porciones</label>
                              <Select
                              value={this.state.optionsPorciones.filter(({value}) => value === this.state.selectedValuePorciones)}
                              onChange={this.handlePorciones}
                              options = {this.state.optionsPorciones}
                              isDisabled = {this.state.displayPorciones}
                              placeholder = {'Seleccionar'}/>
                           </div>
                          </div>
                          <br/>
                          <div className="row">
                           <div className="col-md-6">
                              <label>Sabor</label>
                              <Select 
                               value={this.state.optionsSabor.filter(({value}) => value === this.state.selectedValueSabor)}
                               onChange={this.handleSabor}
                              options = {this.state.optionsSabor}
                              isDisabled = {this.state.displaySabor}
                              placeholder = {'Seleccionar'}/>
                           </div>
                           <div className="col-md-6">
                              <label>Relleno (maxímo 2)</label>
                              <Select 
                              value = {this.state.value}
                              onChange={this.handleRelleno}
                              isMulti
                              options = {this.state.optionsRelleno}
                              isDisabled = {this.state.displayRelleno}
                              placeholder = {'Seleccionar'}/>
                           </div>
                          </div>
                        </div>
                        <br/>
                        <div className="sc-1p5q16i-0 fnhIML">
                        <button color="secondary" className="sc-1p5q16i-2 Nmvat sc-htpNat gEKfxI" onClick={(e)=>this.AddProducto(e, item.productoId, item.clasificacion)}>AGREGAR</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 descripcion">
                    <h2 className="descripcionTitulo">Acerca del producto</h2>
                    <span className="wysiwyg sc-ifAKCX drzNke">
                      {item.descripcion}
                    </span>
                  </div>
                </div>

                :

                (item.medida === 2 && item.acabadoId === '0' && item.categoriaId !== 1 )?

                <div className="row">
                  <div className="col-lg-12 detalle">
                    <div className="row">
                      <div className="col-lg-6 dp-img">
                        <figure>
                          <img src={Common.urlIMG+item.imagen} alt="" className="img-adaptable" />
                        </figure>
                      </div>
                      <div className="col-lg-6 dp-datos">
                        <h4 style={{ marginBottom : "8px"}} className="sc-ifAKCX iGWcpD">
                          {item.nombre}
                        </h4>
                        <p className='card-text' id='cantidadPrincipal'>
                          {this.state.porciones} Porciones
                        </p>
                        <div style={{ visibility: "visible", marginBottom : "20px"}} className="sc-gisBJw ippVNs">
                          <div className="sc-kjoXOD gjthul">
                            <span color="error" className="sc-ifAKCX gmaHPm">S/ {this.state.precioTotal}.00</span>
                          </div>
                        </div>
                        <div className="container-fluid">
                          <div className="row">
                           <div className="col-md-6">
                              <label>Porciones</label>
                              <Select
                              value={this.state.optionsPorcionesSAcabado.filter(({value}) => value === this.state.selectedValuePorciones)}
                              onChange={this.handlePorcionesSinAcabado}
                              options = {this.state.optionsPorcionesSAcabado}
                              placeholder = {'Seleccionar'}/>
                           </div>
                           <div className="col-md-6">
                              <label>Sabor</label>
                              <Select 
                               value={this.state.optionsSabor.filter(({value}) => value === this.state.selectedValueSabor)}
                               onChange={this.handleSabor}
                              options = {this.state.optionsSabor}
                              isDisabled = {this.state.displaySabor}
                              placeholder = {'Seleccionar'}/>
                           </div>
                          </div>
                          <br/>
                          <div className="row">
                           <div className="col-md-12">
                              <label>Relleno (maxímo 2)</label>
                              <Select 
                              value = {this.state.value}
                              onChange={this.handleRelleno}
                              isMulti
                              options = {this.state.optionsRelleno}
                              isDisabled = {this.state.displayRelleno}
                              placeholder = {'Seleccionar'}/>
                           </div>
                          </div>
                        </div>
                        <br/>
                        <div className="sc-1p5q16i-0 fnhIML">
                        <button color="secondary" className="sc-1p5q16i-2 Nmvat sc-htpNat gEKfxI" onClick={(e)=>this.AddProducto(e, item.productoId, item.clasificacion)}>AGREGAR</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 descripcion">
                    <h2 className="descripcionTitulo">Acerca del producto</h2>
                    <span className="wysiwyg sc-ifAKCX drzNke">
                      {item.descripcion}
                    </span>
                  </div>
                </div>

                :

                (item.medida === 2 && item.categoriaId === 1)?

                <div className="row">
                  <div className="col-lg-12 detalle">
                    <div className="row">
                      <div className="col-lg-6 dp-img">
                        <figure>
                          <img src={Common.urlIMG+item.imagen} alt="" className="img-adaptable" />
                        </figure>
                      </div>
                      <div className="col-lg-6 dp-datos">
                        <h4 style={{ marginBottom : "8px"}} className="sc-ifAKCX iGWcpD">
                          {item.nombre}
                        </h4>
                        <p className='card-text' id='cantidadPrincipal'>
                          {this.state.porciones} Porciones
                        </p>
                        <div style={{ visibility: "visible", marginBottom : "20px"}} className="sc-gisBJw ippVNs">
                          <div className="sc-kjoXOD gjthul">
                            <span color="error" className="sc-ifAKCX gmaHPm">S/ {this.state.precioTotal}.00</span>
                          </div>
                        </div>
                        <div className="container-fluid">
                          <div className="row">
                           <div className="col-md-12">
                              <label>Porciones</label>
                              <Select
                              value={this.state.optionsPorcionesSAcabado.filter(({value}) => value === this.state.selectedValuePorciones)}
                              onChange={this.handlePorcionesSinAcabado}
                              options = {this.state.optionsPorcionesSAcabado}
                              placeholder = {'Seleccionar'}/>
                           </div>
                          </div>
                        </div>
                        <br/>
                        <div className="sc-1p5q16i-0 fnhIML">
                        <button color="secondary" className="sc-1p5q16i-2 Nmvat sc-htpNat gEKfxI" onClick={(e)=>this.AddProducto(e, item.productoId, item.clasificacion,'', item.categoriaId)}>AGREGAR</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 descripcion">
                    <h2 className="descripcionTitulo">Acerca del producto</h2>
                    <span className="wysiwyg sc-ifAKCX drzNke">
                      {item.descripcion}
                    </span>
                  </div>
                </div>

                :

                <div className="row">
                  <div className="col-lg-12 detalle">
                    <div className="row">
                      <div className="col-lg-6 dp-img">
                        <figure>
                          <img src={Common.urlIMG+item.imagen} alt="" className="img-adaptable" />
                        </figure>
                      </div>
                      <div className="col-lg-6 dp-datos">
                        <h4 style={{ marginBottom : "8px"}} className="sc-ifAKCX iGWcpD">
                          {item.nombre}
                        </h4>
                        <p className='card-text' id='cantidadPrincipal'>
                          {this.state.porciones} Caja
                        </p>
                        <div style={{ visibility: "visible", marginBottom : "20px"}} className="sc-gisBJw ippVNs">
                          <div className="sc-kjoXOD gjthul">
                            <span color="error" className="sc-ifAKCX gmaHPm">S/ {this.state.precioTotal}.00</span>
                          </div>
                        </div>
                        <div className="container-fluid">
                          <div className="row">
                           <div className="col-md-12">
                              <label>Caja</label>
                              <Select
                              value={this.state.optionsPorcionesSAcabado.filter(({value}) => value === this.state.selectedValuePorciones)}
                              onChange={this.handlePorcionesSinAcabado}
                              options = {this.state.optionsPorcionesSAcabado}
                              placeholder = {'Seleccionar'}/>
                           </div>
                          </div>
                        </div>
                        <br/>
                        <div className="sc-1p5q16i-0 fnhIML">
                        <button color="secondary" className="sc-1p5q16i-2 Nmvat sc-htpNat gEKfxI" onClick={(e)=>this.AddProducto(e, item.productoId, item.clasificacion, item.medida)}>AGREGAR</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 descripcion">
                    <h2 className="descripcionTitulo">Acerca del producto</h2>
                    <span className="wysiwyg sc-ifAKCX drzNke">
                      {item.descripcion}
                    </span>
                  </div>
                </div>
              )
            })
          }
          <Sugar color= "#ffc107" customLoading={this.state.loading} />
        </div>
        <Footer />
        </section>
        <aside className="col-md-3 col-lg-3 bg-aside aside aside-1 bordes-ds" style={{display: this.state.displayAside}}>
          <Aside
          state={{
              producto : this.state.productoId,
              varDisplay : this.state.varDisplay,
              estadoCesta : this.state.estadoCesta,
              count : this.state.count,
              clasificacion : this.state.clasificacion,
              productoDetalleId : this.state.productoDetalleId,
              cantidad : this.state.cantidad,
              preciod : this.state.precioTotal,
              acabado: this.state.acabadoId,
              sabor : this.state.saborId,
              relleno1 : this.state.rellenoId1,
              relleno2 : this.state.rellenoId2,
              medida :  this.state.medida,
              categoria : this.state.categoria,
          }}
          
          history = {this.props.history}
          contadorProductos = {this.contadorProductos}
          />
        </aside>
        <div className="i2ljv5-0 bFGuQ mostrar-aside">
            <button onClick={(e) => this.mostarAside(e)} class="sc-htpNat dyjgNq"><svg fill="#ffffff" viewBox="0 0 24 24" type="cart" class="sc-bxivhb hXLYRj"><path d="M21.333 10.323h-1.7l-5.466-6.1a.673.673 0 00-1 .9l4.666 5.233H6.167l4.666-5.233a.673.673 0 10-1-.9l-5.433 6.1H2.667a.667.667 0 100 1.333h18.666a.667.667 0 100-1.333zM5.333 20.99h13.334l1.6-8h-16.5l1.566 8z"></path></svg>
            <span>
                {
                   this.state.cantidadProducto
                }
            </span>
            </button>
        </div>
      </div>
      
    );
  }
}
