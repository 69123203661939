import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CulqiProvider, Culqi } from "react-culqi";
import Common from "./Common";
import axios from "axios";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";
import {Sugar} from 'react-preloaders';
import uuid from 'react-uuid'
import ClipLoader from "react-spinners/ClipLoader";

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class Compra extends Component{

    constructor(props) {
        super(props);
        
        let precio = parseInt(localStorage.getItem('precioTotal'));
        let dias = parseInt(localStorage.getItem('diaEntrega'));
        let emailGet = localStorage.getItem('email');
        var dataProducto = JSON.parse(localStorage.getItem('dataProducto'))

        this.state = { 
            codigoPedido : uuid(),
            defaultIndex : 0,
            isGoing: false,
            subtotal : precio,
            constoEnvio : 0,
            total : precio,
            disabledEnvio : true,
            disabledFacturacion : true,
            disabledPago : true,
            disabledDedicatoria : true,
            displayBoleta : 'none',
            displayFactura : 'none',
            displayTarjeta : 'none',
            displayDeposito : 'none',
            displayEnvioDomicilio : 'none',
            displayEnvioTienda: 'none',
            textodedicatoria : '',
            dataDistrito: [],
            selectedValue : '',
            startDate: null,
            direccion : '',
            referencia : '',
            selectedValueComprobante : '',
            dni : 0,
            nombrescompletos : '',
            ruc : 0,
            razonsocial : '',
            direccionfiscal : '',
            selectValueMedioPago : '',
            diasEntrega : dias,
            selectedValueCuentas : '',
            nombreCuenta : '',
            cta : '',
            banco : '',
            displayCuenta : 'none',
            dataUsuario : '',
            dataProducto : dataProducto,
            loading: false,
            pedidoId : 0,
            tipoCuentaBancaria : '',
            estadoBottonCompra : false,
            codigoDescuento : '',
            descuento : 0,
            codigoUsado : 0,
            codigoDescuentoId : 0,
            nombreDistrito: '',
            valorEnvio: '',
            selectEnvioNombre: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.handleMedioPago = this.handleMedioPago.bind(this);
        this.handleCuentas = this.handleCuentas.bind(this);
        this.handleEnvio = this.handleEnvio.bind(this);


        axios.get(`${Common.urlAPI}/Distrito`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.distritoId,
                    label: item.nombre,
                };
            });

            this.setState({dataDistrito : source});
        })


        axios.get(`${Common.urlAPI}/Persona/Listar?Email=${emailGet}`,config).then((response) => {

            let source =  response.data.detail.map(item => {
                return {
                    userId: item.userId,
                    nombre: item.nombre,
                    apellidos : item.apellidos,
                    celular : item.celular,
                    email : item.email,
                    fechaCumpleano : item.fechaCumpleano
                };
            });


            this.setState({
                dataUsuario : source
            })

        })

        this.optionsC = [
            {value: '1', label: 'Boleta'},
            {value: '2', label: 'Factura'}
        ];

        this.mediopago = [
            {value: '1', label: 'TARJETA'},
            {value: '2', label: 'DEPOSITO Y/O TRANSFERENCIA BANCARIA'}
        ];

        this.cuentas = [
            {value: '1', label: 'BCP'},
            {value: '2', label: 'INTERBANK'},
            {value: '3', label: 'SCOTIABANK'},
            {value: '4', label: 'BBVA CONTINENTAL'}
        ];

        this.optionsEnvio = [
            {value: '1', label: 'Domicilio'},
            {value: '2', label: 'Recojo en tienda'}
        ]

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const name = target.name;

        this.setState({
        [name]: value
        });

        target.checked === true ? this.setState({disabledDedicatoria : false}) : this.setState({disabledDedicatoria : true})
    }


    handleChange(selectedOption) {

        this.setState({ selectedValue : selectedOption.value, nombreDistrito : selectedOption.label});

        axios.get(`${Common.urlAPI}/Distrito/Precio/${selectedOption.value}`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    precio : item.costo
                    
                };
            });

            let total = parseInt(this.state.subtotal) + parseInt(source[0].precio) - this.state.descuento;

            this.setState({ constoEnvio : source[0].precio, total : total});
        })

    }

    setField (e) {
        this.setState({[e.target.name]: e.target.value});
    }

    nextDedicatoria(e){
        e.preventDefault();

        (this.state.isGoing === true)?
          (this.state.textodedicatoria === '')?

          Common.notify('completar todo los campos', 'error')

          :
           
          this.setState({disabledEnvio : false, defaultIndex : 1})

        :
         this.setState({disabledEnvio : false, defaultIndex : 1})
    }


    handleChangeDate = date => {
        this.setState({
          startDate: date
        });
    };

    nextEnvio(e){
        e.preventDefault();

        if(this.state.valorEnvio === '1'){
            if(this.state.selectedValue !== '' && this.state.direccion !== '' && this.state.referencia !== '' && this.state.startDate !== null){
            
                this.setState({disabledFacturacion : false, defaultIndex : 2});
    
            }else{
                Common.notify('completar todo los campos', 'error');
            }
        }else{
            console.log(this.state.startDate)
            if(this.state.startDate !== null){
                this.setState({disabledFacturacion : false, defaultIndex : 2});
            }else{
                Common.notify('Seleccionar una fecha', 'error');
            }
        }
        
    }

    handleChange2(selectedOption) {

        this.setState({ selectedValueComprobante : selectedOption.value});


        if(selectedOption.value === '1'){
            this.setState({displayBoleta : 'block',displayFactura : 'none'})
        }else{
            this.setState({displayBoleta : 'none', displayFactura : 'block'})
        }      
        
    }

    nextFacturacion(e){
        e.preventDefault();

        if(this.state.selectedValueComprobante === '1'){

            if(this.state.dni !== 0 && this.state.dni.length === 8 && this.state.nombrescompletos !== ''){

                this.setState({disabledPago : false, defaultIndex : 3});

            }else{
                Common.notify('completar todo los campos correctamente', 'error');
            }

        }else{

            if(this.state.ruc !== 0 && this.state.ruc.length === 11 && this.state.razonsocial !== '' && this.state.direccionfiscal !== ''){

                this.setState({disabledPago : false, defaultIndex : 3});

            }else{
                Common.notify('completar todo los campos correctamente', 'error');
            }

        }
    }

    handleEnvio(selectOption){

        this.setState({ valorEnvio :  selectOption.value, selectEnvioNombre: selectOption.label,direccion : '', referencia: '', selectedValue: 0});

        if(selectOption.value === '1'){
            this.setState({displayEnvioDomicilio : 'block',displayEnvioTienda : 'none', selectedValue : ''})
        }else{
            
            let nuevoTotal = this.state.subtotal;

            this.setState({displayEnvioDomicilio : 'none',displayEnvioTienda : 'block', constoEnvio: 0, total: nuevoTotal})
        }
    }

    handleMedioPago(selectedOption) {

        this.setState({ selectValueMedioPago : selectedOption.value});
        
        if(selectedOption.value === '1'){
            this.setState({displayTarjeta : 'block',displayDeposito : 'none'})
        }else{
            this.setState({displayTarjeta : 'none',displayDeposito : 'block'})
        }
    }

    handleCuentas(selectedOption) {

        this.setState({ selectedValueCuentas : selectedOption.value});

        if(selectedOption.value === '1'){
            this.setState({
                displayCuenta : 'block',
                nombreCuenta : 'JHANET RAMOS',
                banco :  'BCP',
                tipoCuentaBancaria : 'CTA. AHORRO SOLES',
                cta : '19374805345052'
            })
        }

        if(selectedOption.value === '2'){
            this.setState({
                displayCuenta : 'block',
                nombreCuenta : 'EXPERIENCE GROUP S.A.C',
                tipoCuentaBancaria : 'CTA. CORRIENTE SOLES',
                banco :  'Interbank',
                cta : '365-300148281-8'
            })
        }

        if(selectedOption.value === '3'){
            this.setState({
                displayCuenta : 'block',
                nombreCuenta : 'JHANET RAMOS',
                tipoCuentaBancaria : 'CTA. AHORRO SOLES',
                banco :  'Scotiabank',
                cta : '6800184720'
            })
        }

        if(selectedOption.value === '4'){
            this.setState({
                displayCuenta : 'block',
                nombreCuenta : 'BRUNO HELGUERO / JHANET RAMOS',
                tipoCuentaBancaria : 'CTA. AHORRO SOLES',
                banco :  'BBVA Continental',
                cta : '0011-0814-0237595497'
            })
        }
    }

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }


    CrearCargoCulqui(token, email){

        const auth = {
            headers: {
                Authorization: Common.privateKeyCulqui
            }
        };

        let NroDocumento = 0;
        let NombresCompletos = '';

        if(this.state.selectedValueComprobante === '1'){
            NroDocumento = this.state.dni
            NombresCompletos = this.state.nombrescompletos
        }else{
            NroDocumento = this.state.ruc
            NombresCompletos = this.state.razonsocial
        }

        this.jsonRegistrarPedido = {
            "UserId" : this.state.dataUsuario[0].userId,
            "Dedicatoria" : this.state.textodedicatoria,
            "DistritoId" : this.state.selectedValue,
            "DireccionEnvio" : this.state.direccion,
            "Referencia" : this.state.referencia,
            "FechaEntrega" : this.convert(this.state.startDate),
            "TipoComprobante" : this.state.selectedValueComprobante,
            "NroDocumento" : parseInt(NroDocumento),
            "NombresCompletos" : NombresCompletos,
            "RazonSocial" : this.state.razonsocial,
            "DireccionFiscal"  : this.state.direccionfiscal,
            "TipoPago" : this.state.selectValueMedioPago,
            "Subtotal" : this.state.subtotal,
            "Delivery" : this.state.constoEnvio,
            "Total" : this.state.total,
            "EstadoPedido" : 1,
            "CodigoPedido" : this.state.codigoPedido,
            "Descuento" : this.state.descuento,
            "MetodoEnvio" : this.state.valorEnvio,
        }

        

        this.params ={
            "amount": this.state.total+'00',
            "currency_code": "PEN",
            "description" : "Dolce Salato",
            "email": email,
            "source_id": token
        }

        this.setState({
            estadoBottonCompra : true
        })

        axios.post(`https://api.culqi.com/v2/charges`, this.params, auth)
            .then((response) => {

               if(response.status === 201){

                    axios.post(`${Common.urlAPI}/Pedido/Registrar`, this.jsonRegistrarPedido , config)
                    .then((response) => {

                        if(response.status === 200){
                            
                            axios.get(`${Common.urlAPI}/Pedido/ListarNro/${this.state.codigoPedido}`,config).then((response) => {

                                let source = response.data.detail.map(item => {
                                    return {
                                        pedidoId: item.pedidoId
                                    };
                                });
                    
                                for(let i=0; i < this.state.dataProducto.length; i++){
                                    
                                    this.JsonDetallePedido = {
                                        "pedidoId" : source[0].pedidoId,
                                        "productoDetalleId" : this.state.dataProducto[i].productoDetalleId,
                                        "precio" : this.state.dataProducto[i].precio,
                                        "cantidadAgregados" : this.state.dataProducto[i].cantidadAgregados,
                                        "saborId" : this.state.dataProducto[i].saborId,
                                        "rellenoId1" : this.state.dataProducto[i].rellenoId1,
                                        "rellenoId2" : this.state.dataProducto[i].rellenoId2,
                                        "acabadoId" : this.state.dataProducto[i].acabadoId
                                    }

                                    axios.post(`${Common.urlAPI}/DetallePedido/Registrar`, this.JsonDetallePedido, config)
                                    .then((response) => {

                                    }).catch((error) => {
                                        console.log(error.response)
                                    })
                                }

                                if(this.state.codigoUsado !==0){

                                    this.jsonCodigoDescuento = {
                                        "CodigoId" : this.state.codigoDescuentoId,
                                        "Activo" : 0
                                    }
                                    
                                    axios.post(`${Common.urlAPI}/CodigoDescuento/Actualizar`, this.jsonCodigoDescuento , config)
                                    .then((response) => {
            
                                    }).catch((error) => {
                                        
                                    })
                                }

                                this.jsonPedido = {
                                    "email" : this.state.dataUsuario[0].email,
                                    "nombre"  : this.state.dataUsuario[0].nombre,
                                    "subTotal" : this.state.subtotal,
                                    "delivery" : this.state.constoEnvio,
                                    "total" : this.state.total,
                                    "producto" : this.state.dataProducto,
                                    "pedidoId" : source[0].pedidoId,
                                    "nombresCompletos" : this.state.nombrescompletos,
                                    "direccionEnvio" : this.state.direccion,
                                    "tipoPagoNombre" : 'Tarjeta',
                                    "recordatorio" : '',
                                    "fechaEntregaCorreo" : this.convert(this.state.startDate),
                                    "Descuento" : this.state.descuento,
                                    "distrito"  : this.state.nombreDistrito,
                                    "celular"  : this.state.dataUsuario[0].celular,
                                    "MetodoEnvioNombre" : this.state.selectEnvioNombre
                                }
        
                                axios.post(`${Common.urlAPI}/EnviarCorreo/Compra`, this.jsonPedido , config)
                                    .then((response) => {
            
                                    }).catch((error) => {
                                        
                                    })

                                
                            })
                        }
                        
                    
                    })
                        localStorage.removeItem('dataProducto');
                        localStorage.removeItem('diaEntrega');
                        localStorage.removeItem('precioTotal');
        
                        const { history } = this.props
                        history.push('/graciasCompra')
               }

            
            })
            .catch(error => {
                this.setState({
                    estadoBottonCompra : false
                })
               Common.notify("Ups! Algo salió mal. Intente nuevamente o utilice otra tarjeta.", "error")
            });

    }

    FinalizarCompra(e){
       e.preventDefault();
       
       let NroDocumento = 0;
       let NombresCompletos = '';

       if(this.state.selectedValueComprobante === '1'){
           NroDocumento = this.state.dni
           NombresCompletos = this.state.nombrescompletos
       }else{
           NroDocumento = this.state.ruc
           NombresCompletos = this.state.razonsocial
       }

       console.log(this.state);

       this.jsonRegistrarPedido = {
        "UserId" : this.state.dataUsuario[0].userId,
        "Dedicatoria" : this.state.textodedicatoria,
        "DistritoId" : this.state.selectedValue,
        "DireccionEnvio" : this.state.direccion,
        "Referencia" : this.state.referencia,
        "FechaEntrega" : this.convert(this.state.startDate),
        "TipoComprobante" : this.state.selectedValueComprobante,
        "NroDocumento" : parseInt(NroDocumento),
        "NombresCompletos" : NombresCompletos,
        "RazonSocial" : this.state.razonsocial,
        "DireccionFiscal"  : this.state.direccionfiscal,
        "TipoPago" : this.state.selectValueMedioPago,
        "Subtotal" : this.state.subtotal,
        "Delivery" : this.state.constoEnvio,
        "Total" : this.state.total,
        "EstadoPedido" : 2,
        "CodigoPedido" : this.state.codigoPedido,
        "Descuento" : this.state.descuento,
        "MetodoEnvio" : this.state.valorEnvio,
        }

        if(this.state.selectedValueCuentas !== ''){

            this.setState({ loading : true });

            axios.post(`${Common.urlAPI}/Pedido/Registrar`, this.jsonRegistrarPedido , config)
            .then((response) => {

                if(response.status === 200){
                            
                    axios.get(`${Common.urlAPI}/Pedido/ListarNro/${this.state.codigoPedido}`,config).then((response) => {

                        let source = response.data.detail.map(item => {
                            return {
                                pedidoId: item.pedidoId
                            };
                        });
            
                        for(let i=0; i < this.state.dataProducto.length; i++){
                            
                            this.JsonDetallePedido = {
                                "pedidoId" : source[0].pedidoId,
                                "productoDetalleId" : this.state.dataProducto[i].productoDetalleId,
                                "precio" : this.state.dataProducto[i].precio,
                                "cantidadAgregados" : this.state.dataProducto[i].cantidadAgregados,
                                "saborId" : this.state.dataProducto[i].saborId,
                                "rellenoId1" : this.state.dataProducto[i].rellenoId1,
                                "rellenoId2" : this.state.dataProducto[i].rellenoId2,
                                "acabadoId" : this.state.dataProducto[i].acabadoId
                            }

                            axios.post(`${Common.urlAPI}/DetallePedido/Registrar`, this.JsonDetallePedido, config)
                            .then((response) => {

                            }).catch((error) => {
                                console.log(error.response)
                            })
                        }


                        if(this.state.codigoUsado !==0){

                            this.jsonCodigoDescuento = {
                                "CodigoId" : this.state.codigoDescuentoId,
                                "Activo" : 0
                            }
                            
                            axios.post(`${Common.urlAPI}/CodigoDescuento/Actualizar`, this.jsonCodigoDescuento , config)
                            .then((response) => {
    
                            }).catch((error) => {
                                
                            })
                        }

                        this.jsonPedido = {
                            "email" : this.state.dataUsuario[0].email,
                            "nombre"  : this.state.dataUsuario[0].nombre,
                            "subTotal" : this.state.subtotal,
                            "delivery" : this.state.constoEnvio,
                            "total" : this.state.total,
                            "producto" : this.state.dataProducto,
                            "pedidoId" : source[0].pedidoId,
                            "nombresCompletos" : this.state.nombrescompletos,
                            "direccionEnvio" : this.state.direccion,
                            "tipoPagoNombre" : 'DEPOSITO Y/O TRANSFERENCIA BANCARIA',
                            "recordatorio" : 'No olvides que debes realizar tu transferencia y/o depósito del 100% en un plazo máximo de 24 Horas.  Caso contrario tu pedido queda automáticamente cancelado.',
                            "fechaEntregaCorreo" : this.convert(this.state.startDate),
                            "Banco" : this.state.banco,
                            "TipoCuenta" : this.state.tipoCuentaBancaria,
                            "Cuenta" : this.state.cta,
                            "Descuento" : this.state.descuento,
                            "distrito"  : this.state.nombreDistrito,
                            "celular"  : this.state.dataUsuario[0].celular,
                            "MetodoEnvioNombre" : this.state.selectEnvioNombre
                        }
    
    
                        axios.post(`${Common.urlAPI}/EnviarCorreo/Compra`, this.jsonPedido , config)
                            .then((response) => {
    
                            }).catch((error) => {
                                
                            })

                        
                    })
                }
                
                localStorage.removeItem('dataProducto');
                localStorage.removeItem('diaEntrega');
                localStorage.removeItem('precioTotal');

                const { history } = this.props
                history.push('/graciasCompra')
            })
            .catch(error => {
                console.log(error.response);
                this.setState({ loading : false });
                Common.notify("Ups! Algo salió mal. Intente nuevamente realizar su pedido.", "error")
             });

        }else{
            Common.notify("Seleccione un Banco", "error")
        }

        
    }

    AplicarDescuento(e) {
        e.preventDefault();

        if(this.state.codigoDescuento !== ''){

            axios.get(`${Common.urlAPI}/CodigoDescuento/Listar/${this.state.codigoDescuento}`,config).then((response) => {

                let source = response.data.detail.map(item => {
                    return {
                        codigoId : item.codigoId,
                        codigo : item.codigo,
                        porcentaje : item.porcentaje,
                        activo : item.activo,
                    };
                });
    
                if (source.length !== 0){

                    if(source[0].activo === 1){

                        if(this.state.codigoUsado === 0){

                            let descuento = parseInt(parseInt(this.state.subtotal) * source[0].porcentaje)
                            let total = parseInt(this.state.total) - descuento;
                            this.setState({descuento : descuento, total: total, codigoDescuentoId: source[0].codigoId, codigoUsado : 1})
                        
                        }else{
                            Common.notify("Código ya aplicado", "error")
                        }
                    }else{
                        Common.notify("Ingrese un código valido", "error")
                    }
                    
                }else{
                    Common.notify("Ingrese un código valido", "error")
                }
            })

        }else{
            Common.notify("Ingrese un código", "error")
        }
        
    }

    render(){
        return(
        <section className="col-md-12 col-lg-12 bg-section">
            <div className="container bg-pago">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <h5>CÓDIGO DE DESCUENTO</h5>
                            <p>Introduzca el código y haga clic en el botón "APLICAR" para actualizar el total.</p>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Ingresar Código" aria-label="Ingresar Código" aria-describedby="button-addon2" name="codigoDescuento" onChange={(e)=>this.setField(e)}/>
                                <div className="input-group-append">
                                    <button className="btn btn-dolcesalato" type="button" id="button-addon2" onClick={(e) => this.AplicarDescuento(e)}>APLICAR</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <h5>RESUMEN</h5>
                            <div className="bg-resumen-precio">
                                 <p>Subtotal: <span>S/ {this.state.subtotal}.00</span></p>
                                 <p>Delivery: <span>S/ {this.state.constoEnvio}.00</span></p>
                                 <p>Descuento: <span>S/ {this.state.descuento}.00</span></p>
                            </div>
                            <div className="bg-resumen-total">
                                 <h4>Total: <span>S/ {this.state.total}.00</span></h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                        <Tabs selectedIndex={this.state.defaultIndex}>
                            <TabList>
                            <Tab>DEDICATORIA</Tab>
                            <Tab disabled={this.state.disabledEnvio}>DATOS DE ENVIO</Tab>
                            <Tab disabled={this.state.disabledFacturacion}>DATOS DE FACTURACIÓN</Tab>
                            <Tab disabled={this.state.disabledPago}>MEDIO DE PAGO</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                                <label>
                                                    <input 
                                                    type="checkbox" 
                                                    name="isGoing" 
                                                    checked={this.state.isGoing}
                                                    onChange={this.handleInputChange} />
                                                    &nbsp;
                                                    Deseas una dedicatoria
                                                </label>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Maximo 255 caracteres</label>
                                                <textarea className="form-control" name="textodedicatoria" rows="5" disabled={this.state.disabledDedicatoria} onChange={(e)=>this.setField(e)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <button type="button" className="btn btn-dolcesalato" onClick={(e) => this.nextDedicatoria(e)}>SIGUIENTE</button>
                                </div>
                            </TabPanel>
                            <TabPanel>
                            <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Opciones envío:</label>
                                                <Select
                                                    value={this.optionsEnvio.filter(({value}) => value === this.state.valorEnvio)}
                                                    placeholder={'Seleccione envío'}
                                                    onChange = {this.handleEnvio}
                                                    options= {this.optionsEnvio}
                                                />
                                            </div>
                                            <div className="col-md-12" style={{display : this.state.displayEnvioDomicilio}}>
                                                <br/>
                                                <form>
                                                    <div class="form-group">
                                                        <label>Distrito:</label>
                                                        <Select
                                                            value={this.state.dataDistrito.filter(({value}) => value === this.state.selectedValue)}
                                                            onChange={this.handleChange}
                                                            options={this.state.dataDistrito}
                                                            placeholder={'Seleccione Distrito'}
                                                        />
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label>Dirección:</label>
                                                        <input type="text" className="form-control" name="direccion" onChange={(e)=>this.setField(e)}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Referencia:</label>
                                                        <input type="text" className="form-control" name="referencia" onChange={(e)=>this.setField(e)}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Fecha de Entrega:</label><br/>
                                                        <DatePicker 
                                                            className="form-control"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChangeDate}
                                                            minDate={subDays(new Date(),this.state.diasEntrega)}
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </div>
                                                    <div class="alert alert-warning" role="alert">
                                                        Rango de entrega 10 AM - 6PM. NO CONTAMOS CON HORARIO FIJO ya que nuestro courrier sigue una ruta.  Por favor paciencia.
                                                    </div>
                                                    <button type="button" className="btn btn-dolcesalato" onClick={(e) => this.nextEnvio(e)}>SIGUIENTE</button>
                                                </form>
                                            </div>
                                            <div className="col-md-12" style={{display : this.state.displayEnvioTienda}}>
                                                <br />
                                                <div class="form-group">
                                                    <label>Calle Alfa Mz. B Lote 41, San Borja (Referencia: Altura de estación tren Angamos en Av. Aviación)</label>
                                                </div>
                                                <div class="form-group">
                                                <label>Fecha de Entrega:</label><br/>
                                                    <DatePicker 
                                                        className="form-control"
                                                        selected={this.state.startDate}
                                                        onChange={this.handleChangeDate}
                                                        minDate={subDays(new Date(),this.state.diasEntrega)}
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                <button type="button" className="btn btn-dolcesalato" onClick={(e) => this.nextEnvio(e)}>SIGUIENTE</button>
                                            </div>

                                        </div>
                                    </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form>
                                                <div className="form-group">
                                                    <label>TIPO COMPROBANTE:</label>
                                                    <Select
                                                        value={this.optionsC.filter(({value}) => value === this.state.selectedValueComprobante)}
                                                        onChange={this.handleChange2}
                                                        options={this.optionsC}
                                                        placeholder={'Seleccione comprobante'}
                                                    />
                                                </div>
                                            </form>
                                            <br />
                                            <form style={{display : this.state.displayBoleta}}>
                                                <div className="form-group">
                                                    <label>DNI:</label>
                                                    <input type="number" className="form-control" name="dni"  onChange={(e)=>this.setField(e)}/>
                                                </div>
                                                <div class="form-group">
                                                    <label>NOMBRES Y APELLIDOS:</label>
                                                    <input type="text" className="form-control" name="nombrescompletos"  onChange={(e)=>this.setField(e)}/>
                                                </div>
                                            </form>
                                            <form style={{display : this.state.displayFactura}}>
                                                <div className="form-group">
                                                    <label>N° RUC:</label>
                                                    <input type="number" className="form-control" name="ruc"  onChange={(e)=>this.setField(e)}/>
                                                </div>
                                                <div class="form-group">
                                                    <label>RAZON SOCIAL:</label>
                                                    <input type="text" className="form-control" name="razonsocial"  onChange={(e)=>this.setField(e)}/>
                                                </div>
                                                <div class="form-group">
                                                    <label>DIRECCIÓN FISCAL:</label>
                                                    <input type="text" className="form-control" name="direccionfiscal"  onChange={(e)=>this.setField(e)}/>
                                                </div>
                                            </form>
                                            <button type="button" className="btn btn-dolcesalato" onClick={(e) => this.nextFacturacion(e)}>SIGUIENTE</button>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                    <div className="form-group">
                                        <label>MEDIO PAGO:</label>
                                        <Select
                                            value={this.mediopago.filter(({value}) => value === this.state.selectValueMedioPago)}
                                            onChange={this.handleMedioPago}
                                            options={this.mediopago}
                                            placeholder={'Seleccione Medio Pago'}
                                        />
                                    </div>
                                <div style={{display : this.state.displayTarjeta}}>
                                <div class="alert alert-warning" role="alert">
                                    Por favor no cerrar  ni recargar la página mientras se está procesando la compra…
                                </div>
                                <br/>
                                <CulqiProvider
                                    publicKey= {Common.publicKeyCulqui}
                                    amount={this.state.total+'00'}
                                    title="Dolce Salato"
                                    description = "Productos de Dolce Salato"
                                    options={{
                                        style : {
                                            maincolor : "#ffc107",
                                            logo : "https://dolcesalato.com.pe/static/media/logo.1a4b0ed0.png"
                                        }
                                    }}
                            
                                    onToken={token => {
                                        
                                        this.CrearCargoCulqui(token.id, token.email);
                                    }}
                                    onError={error => {
                                        Common.notify("Algo salio mal, vuelva a intentar", "error");
                                    }}
                                    >
                                    <Culqi>
                                        {({ openCulqi, setAmount, amount }) => {
                                        
                                            return <button className="btn btn-dolcesalato" onClick={openCulqi} disabled={this.state.estadoBottonCompra}>PAGAR Y FINALIZAR</button>
                                            
                                        }}
                                    </Culqi>
                                    
                                </CulqiProvider>
                                </div>
                                <div style={{display : this.state.displayDeposito}}>
                                    <div className="form-group">
                                        <label>Cuentas Bancarias:</label>
                                        <Select
                                            value={this.cuentas.filter(({value}) => value === this.state.selectedValueCuentas)}
                                            onChange={this.handleCuentas}
                                            options={this.cuentas}
                                            placeholder = {"Seleccione un banco"}
                                        />
                                    </div>
                                    <div className="form-group bg-pasos" style={{display: this.state.displayCuenta}}>
                                        <p><strong>Pasos a seguir</strong></p>
                                        <p>1. Hacer el déposito a nombre de:</p>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>NOMBRE</strong>: {this.state.nombreCuenta}</p>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>BANCO</strong>: {this.state.banco}</p>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>{this.state.tipoCuentaBancaria}</strong>: {this.state.cta}</p>
                                        <p>2. Enviar el voucher de la transacción al número <strong>936 884 517</strong> para validar su pedido</p>
                                        <p>3. Nos contactaremos contigo luego de validar el pedido</p>
                                    </div>
                                    {
                                    this.state.loading ? <button disabled={true} style={{opacity : "0.5", cursor : "default"}}  className="btn btn-dolcesalato" onClick={(e) => this.FinalizarCompra(e)}>FINALIZAR&nbsp;&nbsp;<ClipLoader size={25} loading={this.state.loading} /></button>
                                    
                                    : <button className="btn btn-dolcesalato" onClick={(e) => this.FinalizarCompra(e)}>FINALIZAR</button>
                                    }
                                    
                                </div>
                            </TabPanel>
                        </Tabs>
                        </div>
                    </div>
                </div>
                <Sugar color= "#ffc107" customLoading={this.state.loading} />
            </div>
        </section>
        )
    }
}