import React, {Component} from 'react';
import { Link} from 'react-router-dom';

export default class AsideMenu extends Component{

    constructor(props) {
        super(props);
        

        let sesion = localStorage.getItem('sesion');

        this.state = { 
            displayCategorias : "block",
            displayServicios : "none",
            displayNav : "flex",
            estadoHeader : 0,
            displayBocaditos : "none",
            displayCupcakes : "none",
            displayJar : "none",
            ActivaSesion : sesion,
        };

    }

    cambiarMenu(e, idcategoria){
     e.preventDefault();
        if(idcategoria === 1){
          this.setState({
            displayCategorias : "block",
            displayServicios : "none"
          })
        }else{
            this.setState({
                displayCategorias : "none",
                displayServicios : "block",
            })
        }
    }

    subMenu(e, opcion){
        e.preventDefault();
        
        if(opcion === "Bocaditos")
        {
            this.setState({
                displayNav : "none", 
                estadoHeader : 1,
                displayCategorias : "none",
                displayServicios : "none",
                displayBocaditos : "block",
            })
        }

        if(opcion === "Cupcakes"){
            this.setState({
                displayNav : "none", 
                estadoHeader : 1,
                displayCategorias : "none",
                displayServicios : "none",
                displayCupcakes : "block",
            })
        }

        if(opcion == "Jar"){
            this.setState({
                displayNav : "none", 
                estadoHeader : 1,
                displayCategorias : "none",
                displayServicios : "none",
                displayJar : "block",
            })
        }

        if(opcion == "Regresar"){
            this.setState({
                displayNav : "flex", 
                estadoHeader : 0,
                displayCategorias : "block",
                displayServicios : "block",
                displayJar : "none",
                displayCupcakes : "none",
                displayBocaditos : "none",
            })
        }
        
    }

    render(){
        return(
            <aside className="aside-menu">
                <nav className="sc-14zynq1-0 uLIPC" style={{display : this.state.displayNav}}>
                    <a href="/cobertura" className="sc-jzJRlG mpojpo-0 gmKDOD">
                        <div color="secondary" className="mpojpo-1 ilYvRH">
                            <svg fill="#ffffff" viewBox="0 0 24 24" type="location" color="white" className="sc-bxivhb hXLYRj">
                                <path d="M7.05 3.953C8.363 2.703 10.143 2 12 2c1.857 0 3.637.702 4.95 1.953C18.263 5.203 19 6.899 19 8.667c0 6-7 13.333-7 13.333S5 14.667 5 8.667c0-1.768.737-3.464 2.05-4.714zM12 12a3 3 0 100-6 3 3 0 000 6z"></path>
                            </svg>
                        </div>
                        Cobertura
                    </a>
                    {
                        (this.state.ActivaSesion === '1')?
                        <a href="/miCuenta" className="sc-jzJRlG mpojpo-0 gmKDOD">
                        <div color="secondary" className="mpojpo-1 ilYvRH">
                            <svg fill="#ffffff" viewBox="0 0 24 24" type="profile" color="white" className="sc-bxivhb hXLYRj">
                                <path d="M17.866 18.334c-3.333-.634-3.333-1.667-3.333-2.334v-.4a6.066 6.066 0 001.733-2.933h.033c1 0 1.267-2.1 1.267-2.433 0-.333.033-1.566-1-1.566 2.166-6-3.667-8.4-8.066-5.333-1.8 0-1.967 2.666-1.3 5.333-1.034 0-1 1.266-1 1.566 0 .3.233 2.433 1.266 2.433A6.066 6.066 0 009.2 15.6v.4c0 .667 0 1.734-3.334 2.334A4.833 4.833 0 002 22h19.732a4.899 4.899 0 00-3.866-3.666z"></path>
                            </svg>
                        </div>
                        Mi Cuenta
                        </a>
                        :
                        <a href="login" className="sc-jzJRlG mpojpo-0 gmKDOD">
                        <div color="secondary" className="mpojpo-1 ilYvRH">
                            <svg fill="#ffffff" viewBox="0 0 24 24" type="profile" color="white" className="sc-bxivhb hXLYRj">
                                <path d="M17.866 18.334c-3.333-.634-3.333-1.667-3.333-2.334v-.4a6.066 6.066 0 001.733-2.933h.033c1 0 1.267-2.1 1.267-2.433 0-.333.033-1.566-1-1.566 2.166-6-3.667-8.4-8.066-5.333-1.8 0-1.967 2.666-1.3 5.333-1.034 0-1 1.266-1 1.566 0 .3.233 2.433 1.266 2.433A6.066 6.066 0 009.2 15.6v.4c0 .667 0 1.734-3.334 2.334A4.833 4.833 0 002 22h19.732a4.899 4.899 0 00-3.866-3.666z"></path>
                            </svg>
                        </div>
                        Ingresar
                        </a>
                    }
                    
                    <a className="sc-jzJRlG mpojpo-0 gmKDOD menuasidem">
                        <div color="secondary" className="mpojpo-1 ilYvRH">
                            <svg fill="#ffffff" viewBox="0 0 24 24" type="cart" color="white" className="sc-bxivhb hXLYRj">
                                <path d="M21.333 10.323h-1.7l-5.466-6.1a.673.673 0 00-1 .9l4.666 5.233H6.167l4.666-5.233a.673.673 0 10-1-.9l-5.433 6.1H2.667a.667.667 0 100 1.333h18.666a.667.667 0 100-1.333zM5.333 20.99h13.334l1.6-8h-16.5l1.566 8z">
                                </path>
                            </svg>
                        </div>
                        Carrito
                    </a>
                </nav>
                <section className="fyd21w-1 jkRxEs">
                    <header onClick={(e) => this.cambiarMenu(e,1)}>
                        {
                            (this.state.estadoHeader === 0)?
                            <label class="sc-ifAKCX haSlZv">Categorías</label>
                            :
                            <div>
                                <svg onClick={(e)=> this.subMenu(e, "Regresar")} fill="#333333" viewBox="0 0 24 24" type="arrow-left" color="black" class="sc-bxivhb hXLYRj"><path d="M9.328 19.038L2 11.71l7.328-7.327a1.364 1.364 0 011.928 1.928L7.22 10.347h13.416a1.364 1.364 0 010 2.727H7.221l4.035 4.036a1.363 1.363 0 01-1.928 1.928z"></path></svg>
                                <label class="sc-ifAKCX haSlZv">Regresar</label>
                            </div>
                        }
                        
                        
                    </header>
                    <ul className="fyd21w-0 ieUzdu" style={{display : this.state.displayCategorias}}>
                        <a href="/postresTortas" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Postres y Tortas</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <li className="a5n8x1-0 cZgMfr" onClick={(e)=> this.subMenu(e, "Bocaditos")}>
                            <label className="sc-ifAKCX drzNke">Bocaditos</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </li>
                        <li className="a5n8x1-0 cZgMfr" onClick={(e)=> this.subMenu(e, "Cupcakes")}>
                            <label className="sc-ifAKCX drzNke">Cupcakes</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </li>
                        <a href="/nakedCake" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Naked Cake</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <li className="a5n8x1-0 cZgMfr" onClick={(e)=> this.subMenu(e, "Jar")}>
                            <label className="sc-ifAKCX drzNke">Jar</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </li>
                        <a href="/combos" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Combos</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a href="/tendencias" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Tendencias</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        {/* <a href="/fiestasPatrias" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Fiestas Patrias</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a> */}
                        {/* <a href="/halloween" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Halloween</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a> */}
                         <a href="/navidad" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Navidad</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        {/* <a href="/sanvalentin" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">San Valentín</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a> */}
                        {/* <a href="/diadelamadre" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Día de la Madre</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a> */}
                        {/* <a href="/diadelpadre" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Día del Padre</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a> */}
                    </ul>

                    <ul className="fyd21w-0 ieUzdu" style={{display : this.state.displayBocaditos}}>
                        <a href="/bocaditosDulces" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Bocaditos Dulces</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a href="/bocaditosSalados" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Bocaditos Salados</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a href="/piqueosSaboresPeruanos" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Piqueos y Sabores Peruanos</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                    </ul>
                    <ul className="fyd21w-0 ieUzdu" style={{display : this.state.displayCupcakes}}>
                        <a href="/cupcakesTradicionales" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Cupcakes Tradicionales</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a href="/cupcakesGigantes" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Cupcakes Gigantes</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                    </ul>
                    <ul className="fyd21w-0 ieUzdu" style={{display : this.state.displayJar}}>
                        <a href="/jarCakes" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Jar Cakes</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a href="/jarChessecakes" className="a5n8x1-0 cZgMfr">
                            <label className="sc-ifAKCX drzNke">Jar Cheesecakes</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                    </ul>
                    <header onClick={(e) => this.cambiarMenu(e,2)}>
                        {
                            (this.state.estadoHeader === 0)?
                            <label class="sc-ifAKCX haSlZv">Servicios Adicionales</label>
                            :
                            <div>
                                
                            </div>
                        }
                        
                    </header>
                    <ul className="fyd21w-0 ieUzdu" style={{display : this.state.displayServicios}}>
                        <a className="a5n8x1-0 cZgMfr" href="/decoracionMenaje">
                            <label className="sc-ifAKCX drzNke">Decoración Menaje</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a className="a5n8x1-0 cZgMfr" href="/mozosBarman">
                            <label className="sc-ifAKCX drzNke">Mozos y Barman</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a className="a5n8x1-0 cZgMfr" href="/comidaEventos">
                            <label className="sc-ifAKCX drzNke">Comida para Eventos</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a className="a5n8x1-0 cZgMfr" href="/coffeeBreak">
                            <label className="sc-ifAKCX drzNke">Coffee Break</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a className="a5n8x1-0 cZgMfr" href="/snackCart">
                            <label className="sc-ifAKCX drzNke">Snack Cart</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                        <a className="a5n8x1-0 cZgMfr" href="/degustacionesCorporativas">
                            <label className="sc-ifAKCX drzNke">Degustaciones Corporativas</label>
                            <svg fill="#333333" viewBox="0 0 24 24" type="caret-right" color="black" className="sc-bxivhb hNjuDO">
                                <path d="M6 4.145a2.139 2.139 0 00.629 1.517l6.177 6.359-6.177 6.343a2.146 2.146 0 003.034 3.034l9.211-9.377-9.211-9.393A2.146 2.146 0 006 4.145z">
                                </path>
                            </svg>
                        </a>
                    </ul>
                </section>
            </aside>
        )
    }
}