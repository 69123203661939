import React, { Component } from 'react';
import AsideMenu from './AsideMenu';

import Menu from './Menu';



export default class Layout extends Component {

  constructor(props) {
    super(props);
    

    this.state = { 
    };
  }

  render() {
    return (
        <div>
            <Menu  />
            <div className="container-fluid contenedor-gn">
                <div className="row">
                    <AsideMenu />
                    {this.props.children}
                </div>
            </div>
        </div>
    );
  }
}