import React, {Component} from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Common from "./Common";
import axios from "axios";
import {Sugar} from 'react-preloaders';
import ClipLoader from "react-spinners/ClipLoader";

import comida from "../images/servicios/comida.jpg"

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class ComidaEventos extends Component{

    constructor(props) {
        super(props);

        this.state = { 
            startDate: new Date(),
            nombres: '',
            celular: 0,
            email: '',
            categoria: '',
            numeroPersonas: '',
            descripciondicional: '',
            selectedValue: '',
            loading: false
        };

        this.optionsCategoria = [
            {value: '1', label: 'Almuerzo Criollo'},
            {value: '2', label: 'Almuerzo Marino'},
            {value: '3', label: 'Comida Italiana'},
            {value: '4', label: 'Almuerzo Oriental'},
            {value: '5', label: 'Almuerzo de la Sierra'},
            {value: '6', label: 'Almuerzo de la Selva'},
            {value: '7', label: 'Cena Navideña'},
            {value: '8', label: 'Chancho al cilindro'},
            {value: '9', label: 'Caja China'},
            {value: '10', label: 'Parrillada'},
        ]

        this.handleCategoria = this.handleCategoria.bind(this);
    }
      
    setField (e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleCategoria(selectedOption) {

        this.setState({ selectedValue : selectedOption.value, categoria: selectedOption.label});
    }

    handleChange = date => {
        this.setState({
          startDate: date
        });
    };

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    handleClick(event){
        event.preventDefault();
        
        const {nombres, celular, email, categoria, numeroPersonas, startDate, descripcionAdicional} = this.state;
        
        if(nombres !== '' && celular !== 0 && email !== '' && categoria !== '' && numeroPersonas !== ''){

            this.setState({ loading : true });


            if(celular.length === 9 && /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){

                this.JsonComida = {
                    'nombres' : nombres,
                    'celular' : celular,
                    'email' : email,
                    'categoria' : categoria,
                    'numeroPersonas' : numeroPersonas,
                    'fechaEvento' : this.convert(startDate),
                    'descripcionAdicional' : descripcionAdicional
                }
        
                axios.post(`${Common.urlAPI}/EnviarCorreo/Comida`, this.JsonComida , config)
                        .then((response) => {
                              
                            const { history } = this.props
                            history.push('/graciasServiciosAdicionales')
                        }).catch((error) =>{
                            this.setState({ loading : false });
                            Common.notify("Ocurrio un error vuelve a intentarlo","error");
                        })

            }else{
                Common.notify("Ingresar el formato correcto","error");
            }
        }else{
            Common.notify("Llenar los campos solicitados", "error")
        }
        
     }

    render(){
        return(
            <section className="col-md-12 col-lg-12">
                <div className="container">
                <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+comida+")", backgroundSize : "cover"}}>
                        <h4>Comida para Eventos</h4>
                        <div className="row">
                            <div className="col-md-12 frm-serviciosa">
                                <form>
                                    <div className="form-group">
                                        <label>Nombre o Empresa</label>
                                        <input type="text" className="form-control" placeholder="Nombres o Empresa"  name="nombres" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Número Celular</label>
                                        <input type="number" className="form-control" placeholder="Número celular" name="celular" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Correo electrónico</label>
                                        <input type="text" className="form-control" placeholder="Correo Electrónico" name="email" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Categoría</label>
                                        <Select
                                         value={this.optionsCategoria.filter(({value}) => value === this.state.selectedValue)}
                                         onChange={this.handleCategoria}
                                        options={this.optionsCategoria}
                                        placeholder = {'Seleccionar'}
                                        className="select" />
                                    </div>
                                    <div className="form-group">
                                        <label>Número Personas</label>
                                        <input type="number" className="form-control" placeholder="Número Personas" name="numeroPersonas" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Fecha del Evento</label><br/>
                                        <DatePicker 
                                            className="form-control calendar"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            dateFormat="d/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Descripción Adicional</label>
                                        <textarea className="form-control"  rows="3" name="descripcionAdicional" onChange={(e)=>this.setField(e)}></textarea>
                                    </div>
                                    <br />
                                    {
                                        this.state.loading ? <button disabled={true} style={{opacity : "0.5", cursor : "default"}} className="sc-htpNat btn-saenviar" onClick={(e) => this.handleClick(e)}>ENVIAR&nbsp;&nbsp;<ClipLoader size={25} loading={this.state.loading} /></button>
                                        
                                        : <button className="sc-htpNat btn-saenviar" onClick={(e) => this.handleClick(e)}>ENVIAR</button>
                                    }
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Sugar color= "#ffc107" customLoading={this.state.loading} />
            </section>
        )
    }
    
}
