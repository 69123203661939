import React, {Component}from 'react';
import { Route } from 'react-router';
import './App.css';
import 'react-tabs/style/react-tabs.css';

import Layout  from './components/Layout'
import Home from './components/Home';
import DetalleProducto from './components/DetalleProducto'
import Login from './components/Login'
import Registro from './components/Registro'
import RecuperarContrasenia from './components/RecuperarContrasenia'
import Compra from './components/Compra'
import Cobertura from './components/Cobertura'
import ServiciosAdicionales from './components/ServiciosAdicionales'
import Cotizacion from './components/Cotizacion'
import GraciasRegistro from './components/GraciasRegistro'
import MiCuenta from './components/MiCuenta'
import GraciasCompra from './components/GraciasCompra'
import TerminosCondiciones from './components/TerminosCondiciones'
import ServicioCliente from './components/ServicioCliente'
import PostresTortas from './components/PostresTortas'
import BocaditosDulces from './components/BocaditosDulces'
import BocaditosSalados from './components/BocaditosSalados'
import PiqueosSaboresPeruanos from './components/PiqueosSaboresPeruanos'
import CupcakesTradicionales from './components/CupcakesTradicionales'
import CupcakesGigantes from './components/CupcakesGigantes'
import NakedCake from './components/NakedCake'
import JarCakes from './components/JarCakes'
import JarChessecakes from './components/JarCheesecakes'
import Combos from './components/Combos'
import Tendencias from './components/Tendencias'
import GraciasCotizacion from './components/GraciasCotizacion'
import DecoracionMenaje from './components/DecoracionMenaje'
import MozosBarman from './components/MozosBarman'
import ComidaEventos from './components/ComidaEventos'
import CoffeeBreak from './components/CoffeeBreak'
import SnackCart from './components/SnackCart'
import DegustacionesCorporativas from './components/DegustacionesCorporativas'
import GraciasServiciosAdicionales from './components/GraciasServiciosAdicionales'
import LibroReclamaciones from './components/LibroReclamaciones'
import GraciasReclamo from './components/GraciasReclamo'
import Busqueda from './components/Busqueda'
import GraciasRecuperarContrasena from './components/GraciasRecuperarContrasena'
import FiestasPatrias from './components/FiestasPatrias'
import Halloween from './components/Halloween'
import Navidad from './components/Navidad'
import SanValentin from './components/Sanvalentin'
import DiaDeLaMadre from './components/DiaDeLaMadre'
import DiaDelPadre from './components/DiaDelPadre'

export default class App extends Component {
  render() {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/postresTortas' component={PostresTortas} />
        <Route exact path='/bocaditosDulces' component={BocaditosDulces} />
        <Route exact path='/bocaditosSalados' component={BocaditosSalados} />
        <Route exact path='/piqueosSaboresPeruanos' component={PiqueosSaboresPeruanos} />
        <Route exact path='/cupcakesTradicionales' component={CupcakesTradicionales} />
        <Route exact path='/cupcakesGigantes' component={CupcakesGigantes} />
        <Route exact path='/nakedCake' component={NakedCake} />
        <Route exact path='/jarCakes' component={JarCakes} />
        <Route exact path='/jarChessecakes' component={JarChessecakes} />
        <Route exact path='/combos' component={Combos} />
        <Route exact path='/tendencias' component={Tendencias} />
        <Route exact path='/detalleProducto' component={DetalleProducto} />
        <Route exact path='/compra' component={Compra} />
        <Route exact path='/cobertura' component={Cobertura} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/registro' component={Registro} />
        <Route exact path='/recuperarContrasenia' component={RecuperarContrasenia} />
        <Route exact path='/serviciosAdicionales' component={ServiciosAdicionales} />
        <Route exact path='/cotizacion' component={Cotizacion} />
        <Route exact path='/graciasRegistro' component={GraciasRegistro} />
        <Route exact path='/miCuenta' component={MiCuenta} />
        <Route exact path='/graciasCompra' component={GraciasCompra} />
        <Route exact path='/terminosCondiciones' component={TerminosCondiciones} />
        <Route exact path='/servicioCliente' component={ServicioCliente} />
        <Route exact path='/graciasCotizacion' component={GraciasCotizacion} />
        <Route exact path='/decoracionMenaje' component={DecoracionMenaje} />
        <Route exact path='/mozosBarman' component={MozosBarman} />
        <Route exact path='/comidaEventos' component={ComidaEventos} />
        <Route exact path='/coffeeBreak' component={CoffeeBreak} />
        <Route exact path='/snackCart' component={SnackCart} />
        <Route exact path='/degustacionesCorporativas' component={DegustacionesCorporativas} />
        <Route exact path='/graciasServiciosAdicionales' component={GraciasServiciosAdicionales} />
        <Route exact path='/libroReclamaciones' component={LibroReclamaciones} />
        <Route exact path='/graciasReclamo' component={GraciasReclamo} />
        <Route exact path='/busqueda' component={Busqueda} />
        <Route exact path='/graciasRecuperarContrasena' component={GraciasRecuperarContrasena} />
        <Route exact path='/fiestasPatrias' component={FiestasPatrias} />
        <Route exact path='/halloween' component={Halloween} />
        <Route exact path='/navidad' component={Navidad} />
        <Route exact path='/sanvalentin' component={SanValentin} />
        <Route exact path='/diadelamadre' component={DiaDeLaMadre} />
        <Route exact path='/diadelpadre' component={DiaDelPadre} />
      </Layout>
    )
  }
}

