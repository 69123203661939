import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import Common from './Common';

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class RecuperarContrasenia extends Component{

    constructor(props) {
        super(props);
        
        this.state = { 
            email: '',
        };

        
    }

    setField (e) {

        this.setState({[e.target.name]: e.target.value});
    }

    validarUsuario(e){
        e.preventDefault();
        
        if(this.state.email !== ''){

            axios.get(`${Common.urlAPI}/Persona/ListarContrasenia/${this.state.email}`,config)
            .then((response) => {

                let source = response.data.detail.map(item => {

                    return {
                        email: item.email,
                        contrasena: item.contrasena,
                        nombre : item.nombre
                    };
                })

                console.log(source);

                if(source.length !== 0){

                    this.JsonRecuperar ={
                        "email" : source[0].email,
                        "nombre" : source[0].nombre,
                        "contrasena" : source[0].contrasena
                    }

                    axios.post(`${Common.urlAPI}/EnviarCorreo/Recuperar`, this.JsonRecuperar , config)
                    .then((response) => {

                        const { history } = this.props
                        history.push('/graciasRecuperarContrasena')

                    }).catch((error) => {
                        Common.notify("No se pudo enviar el email", "error")
                    })

                }else{
                    Common.notify('Usuario no registrado', 'error')
                }
                
            })

        }else{
            Common.notify('Completar todos los campos', 'error')
        }
    }

    render(){
        return(
            <div className="container">
            <div className="row">
                <div className="col-md-5 mx-auto">
                <div id="first">
                    <div className="myform form ">
                        <div className="logo mb-3">
                            <div className="col-md-12 text-center">
                                <h3>Restablecer contraseña</h3>
                            </div>
                        </div>
                        <form>
                            <div className="form-group">
                                <label>Ingresa tu correo electrónico</label>
                                <input type="email" name="email"  className="form-control" onChange={(e)=>this.setField(e)}/>
                            </div>
                            <div className="col-md-12 text-center ">
                                <button  className=" btn btn-block mybtn btn-dolcesalato tx-tfm" onClick={(e) => this.validarUsuario(e)}>Continuar</button>
                            </div>
                            <div className="col-md-12 ">
                                <div className="login-or">
                                    <hr className="hr-or" />
                                    <span className="span-or">ó</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <p className="text-center"><Link to={'/login'} id="signup"> Iniciar Sesión</Link></p>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>
        </div>
        </div>
        )
    }
}