import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Common from './Common';
import Footer from './Footer'

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class Registro extends Component{

    constructor(props) {
        super(props);
        
        this.state = { 
            startDate: null,
            nombres : null,
            apellidos : null,
            celular : 0,
            email: null,
            contrasena: null
        };
      }

    handleSubmit(e){
        e.preventDefault();
        e.target.reset();
    }

    handleChange = date => {
        this.setState({
          startDate: date
        });
    };

    setField (e) {

        this.setState({[e.target.name]: e.target.value});
    }

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    Registrar(e){

        e.preventDefault();

        let nombres = this.state.nombres;
        let apellidos = this.state.apellidos;
        let celular = this.state.celular;
        let email = this.state.email;
        let cumpleanio = this.convert(this.state.startDate);
        let contrasena = this.state.contrasena;


        if(nombres !== null){
            
            if(apellidos != null){

                if(celular.length === 9){

                    if(/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){

                        if(cumpleanio !== null){

                            if(contrasena !== null){

                                //IMPLEMENTAR SERVICIO DE REGISTRO

                                axios.get(`${Common.urlAPI}/Persona/Grabar?nombre=${nombres}&apellidos=${apellidos}&celular=${celular}&email=${email}&fechaCumpleano=${cumpleanio}&contrasena=${contrasena}`,config)
                                    .then((response) => {

                                        if(response.data.detail === 1)
                                        {
                                            axios.get(`${Common.urlAPI}/EnviarCorreo/Registro?emailTo=${email}&nombre=${nombres}`, config)
                                            .then((response) => {
                                                    
                                            })

                                            const { history } = this.props
                                            history.push('/graciasRegistro')
                                        }
                                        else{
                                            Common.notify('Usuario ya existe', 'error')
                                        }
                        
                                    })
                                    .catch(error => {
                                        if (error.response.status === 400) {
                                            Common.notify('Sucedió un error', 'error')
                                        } else {
                                            Common.notifyValidation(error.response.data)
                                        }
                        
                                    });
                              
                            }else{
                                Common.notify('Mínimo de ocho caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial', 'error')
                            }
                            
                        }else{
                            Common.notify('Porfavor Completar los campos requeridos', 'error')
                        }

                    }else{
                        Common.notify('Porfavor ingrese un email válido', 'error')
                    }

                }else{
                    Common.notify('Porfavor ingrese un número de celular válido', 'error')
                }

            }else{
                Common.notify('Porfavor Completar los campos requeridos', 'error')
            }

        }else{
            Common.notify('Porfavor Completar los campos requeridos', 'error')
        }
    }
    
    render(){
        return(
            <div className="container-fluid bordes-ds">
            <section className="col-md-12 col-lg-12 col-xl-12">
            <div className="row login-responsive">
                <div className="col-md-4 mx-auto">
                <div id="second">
                    <div className="myform form ">
                        <div className="logo mb-3">
                            <div className="col-md-12 text-center">
                                <h1 >Regístrate</h1>
                            </div>
                        </div>
                        <form className="needs-validation"  onSubmit={this.handleSubmit.bind(this)}>
                            <div className="form-group">
                                <label>Nombres</label>
                                <input type="text"  name="nombres" className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="form-group">
                                <label>Apellidos</label>
                                <input type="text"  name="apellidos" className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="form-group">
                                <label>Celular</label>
                                <input type="number" name="celular"  className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="form-group">
                                <label>Correo Electrónico</label>
                                <input type="email" name="email"  className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="form-group">
                                <label>Fecha Cumpleaños</label><br/>
                                <DatePicker 
                                    className="form-control"
                                    selected={this.state.startDate}
                                    onChange={this.handleChange}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div className="form-group">
                                <label>Contraseña</label>
                                <input type="password" name="contrasena"  className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="col-md-12 text-center mb-3">
                                <button className=" btn btn-block mybtn btn-dolcesalato tx-tfm" onClick={(e) =>this.Registrar(e)}>ENVIAR DATOS</button>
                            </div>
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <p className="text-center"><Link to={'/login'} id="signin">¿Ya tienes una cuenta?</Link></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </section>
        </div>
        )
    }
}