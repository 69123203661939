import React, {Component} from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Common from "./Common";
import axios from "axios";
import { Link } from 'react-router-dom';

/*imagenes*/
import commerce from '../images/iconos/commerce.png'
import { addBusinessDays, endOfYesterday } from 'date-fns';

const config = {
    headers: { Authorization: Common.tokenAPI}
};


var data = [];



export default class Aside extends Component {

    constructor(props) {
        super(props);
        
        let sesion = localStorage.getItem('sesion');
        data = JSON.parse(localStorage.getItem('dataProducto'));
        var precio = localStorage.getItem('precioTotal');

        if(data === null){
            data = [];
        }

        if(precio === null)
        {
            precio = 0;
        }else{
            precio = parseInt(precio);
        }

        this.state = { 
            estado : 0, 
            dataProducto: data, 
            varDisplay: 'block',
            estadoCesta : false,
            precioCompra : precio,
            activarBoton : true,
            existe : false,
            sesion : sesion,
            opacity : '0.8',
            productoId : 0,
            prevProductoId : '',
            pedidominimo : 'Pedido minimo',
            displayPrecio : "inline-block"
        };
    }

    componentDidMount(){

        var precio = localStorage.getItem('precioTotal');

        if(localStorage.getItem('dataProducto') !== null){

            this.setState({
                varDisplay : localStorage.getItem('varDisplay'),
                estadoCesta : localStorage.getItem('estadoCesta')
            });


        }

        if(parseInt(this.state.precioCompra) > 34){
            this.setState({activarBoton : false, opacity : 1, pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
        }

        if(precio < 35){
            this.setState({
                opacity : '0.8',
                activarBoton : true,
                pedidominimo : 'Pedido minimo',
                displayPrecio : "inline-block"
            })
        }

        let totalAgregados = 0;

        for(let i=0; i < data.length; i++){
            
            totalAgregados = data[i].cantidadAgregados + totalAgregados
        }

        this.props.contadorProductos(totalAgregados);
    }

    ProcesarCompra(e){
        e.preventDefault();

        let maximo = 0;

        for(let i = 0; i < data.length ; i++ ){

             if(data[i].diasEntrega > maximo){
                maximo = data[i].diasEntrega
             }
        }

        var X = maximo + 1;
        var number = (X)*(-1); 

        localStorage.setItem('diaEntrega',number);

        if(this.state.precioCompra > 34)
        {
            if(this.state.sesion === '1'){

                const { history } = this.props
                history.push('/compra')

            }else{
                const { history } = this.props
                history.push('/login')
            }
            
        }
        
    }

    vaciarCarrito(e){
        e.preventDefault();
        localStorage.removeItem('dataProducto');
        localStorage.removeItem('precioTotal');

        data = [];

        this.setState({dataProducto : null, varDisplay : 'block', precioCompra : 0, activarBoton: true, opacity : '0.8', pedidominimo : 'Pedido minimo',displayPrecio : "inline-block"})
        this.props.state.producto = 0;
    }

    eliminarProducto(e, productoId){
        e.preventDefault();

        let precio;

        for(let i=0; i < data.length; i++){
            if(data[i].productoId === productoId){
                precio =  parseInt(this.state.precioCompra) - (parseInt(data[i].precio) * parseInt(data[i].cantidadAgregados));
                data.splice(i,1);

                localStorage.setItem('precioTotal',precio);
                localStorage.setItem('dataProducto', JSON.stringify(data));
    
                this.setState({
                    dataProducto : data,
                    precioCompra : precio
                })

                if(precio < 35){
                    this.setState({
                        opacity : '0.8',
                        activarBoton : true,
                        pedidominimo : 'Pedido minimo',
                        displayPrecio : "inline-block"
                    })
                }
            }
        }

        if(data.length === 0){

            localStorage.removeItem('dataProducto');
            localStorage.removeItem('precioTotal');
            data = [];
            this.setState({dataProducto : null, varDisplay : 'block', precioCompra : 0, activarBoton: true, opacity : '0.8'})
        }

        this.props.state.producto = 0;
    }

    eliminarProductoDetalle(e, productoDetalleId, sabor){
        e.preventDefault();

        let precio;
         

        for(let i=0; i < data.length; i++){
            if(data[i].productoDetalleId === productoDetalleId && data[i].Sabor === sabor){
                precio =  parseInt(this.state.precioCompra) - (parseInt(data[i].precio) * parseInt(data[i].cantidadAgregados));
                data.splice(i,1);

                localStorage.setItem('precioTotal',precio);
                localStorage.setItem('dataProducto', JSON.stringify(data));
    
                this.setState({
                    dataProducto : data,
                    precioCompra : precio
                })

                if(precio < 35){
                    this.setState({
                        opacity : '0.8',
                        activarBoton : true,
                        pedidominimo : 'Pedido minimo',
                        displayPrecio : "inline-block"
                    })
                }
            }
        }

        if(data.length === 0){

            localStorage.removeItem('dataProducto');
            localStorage.removeItem('precioTotal');
            data = [];
            this.setState({dataProducto : null, varDisplay : 'block', precioCompra : 0, activarBoton: true, opacity : '0.8'})
        }

        this.props.state.producto = 0;
    }

    componentDidUpdate(prevProps, event) {
       
        if(this.props.state.count !== prevProps.state.count)
        {
            if(data === null){
                data = [];
            }
            
            

            if(this.props.state.producto !== prevProps.state.producto){
                
                if(this.props.state.clasificacion !== 2){

                    let existe = this.validarExiste(this.props.state.producto);

                    if( existe === false){

                        localStorage.setItem('varDisplay',this.props.state.varDisplay);
                        localStorage.setItem('estadoCesta', this.props.state.estadoCesta);

                        this.setState({
                            varDisplay : localStorage.getItem('varDisplay'),
                            estadoCesta : localStorage.getItem('estadoCesta')
                        });

                        axios.get(`${Common.urlAPI}/Producto/DetalleProducto/${this.props.state.producto}`,config).then((response) => {

                            response.data.detail.map(item => {
            
                                    data.push({
                                    'productoId': item.productoId,
                                    'productoDetalleId' : item.productoDetalleId,
                                    'nombre': item.nombre,
                                    'imagen' : item.imagen,
                                    'descripcion' : item.descripcion,
                                    'clasificacion' : item.clasificacion,
                                    'cantidad' : item.cantidad,
                                    'medida' : '',
                                    'precio' : item.precio,
                                    'diasEntrega': item.diasEntrega,
                                    'cantidadAgregados' : 1,
                                    'acabadoId' : '',
                                    'saborId' : '',
                                    'rellenoId1' : '',
                                    'rellenoId2' : '',
                                    'pedidoId' : ''
                                    })
            
                                    let precio = (this.state.precioCompra + parseInt(item.precio));
            
                                    if(precio > 34){

                                        this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                    }

                                    localStorage.setItem('precioTotal',precio);
            
                                    this.setState({precioCompra : precio});

                                    let totalAgregados = 0;

                                    for(let i=0; i < data.length; i++){
                                        
                                        totalAgregados = data[i].cantidadAgregados + totalAgregados
                                    }

                                    this.props.contadorProductos(totalAgregados);
                            });

                            

                            localStorage.setItem('dataProducto', JSON.stringify(data));
            
                            this.setState({
                                dataProducto : data,
                                productoId: 0
                            })
                        })

                    }else{
                        for(let i=0; i < data.length; i++){

                            if (data[i].productoId == this.props.state.producto) {
                                
                                let cantidad = parseInt(data[i].cantidadAgregados) + 1;
            
                                data[i].cantidadAgregados = cantidad;
            
                                let precio = this.state.precioCompra + (parseInt(data[i].precio));

                                if(precio >34){

                                    this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                }

                                localStorage.setItem('precioTotal',precio);
                                this.setState({precioCompra : precio})
                                
                                localStorage.setItem('dataProducto', JSON.stringify(data));

                                let totalAgregados = 0;

                                    for(let i=0; i < data.length; i++){
                                        
                                        totalAgregados = data[i].cantidadAgregados + totalAgregados
                                    }

                                    this.props.contadorProductos(totalAgregados);
                            }
                        }
                    }

                }else{

                    const { productoDetalleId, cantidad, preciod, acabado, sabor, relleno1, relleno2} = this.props.state

                    if(cantidad !== '' && preciod !== '', acabado !== '', sabor !== '' && relleno1 !== ''){

                        let existe = this.validarExisteDetalle(productoDetalleId, sabor);

                        if( existe === false){

                            localStorage.setItem('varDisplay',this.props.state.varDisplay);
                            localStorage.setItem('estadoCesta', this.props.state.estadoCesta);

                            this.setState({
                                varDisplay : localStorage.getItem('varDisplay'),
                                estadoCesta : localStorage.getItem('estadoCesta')
                            });

                            axios.get(`${Common.urlAPI}/Producto/DetalleProducto/${this.props.state.producto}`,config).then((response) => {

                                response.data.detail.map(item => {
                
                                        data.push({
                                        'productoId': item.productoId,
                                        'productoDetalleId' : productoDetalleId,
                                        'nombre': item.nombre,
                                        'imagen' : item.imagen,
                                        'descripcion' : item.descripcion,
                                        'clasificacion' : item.clasificacion,
                                        'cantidad' : cantidad,
                                        'medida' : '',
                                        'precio' : preciod,
                                        'diasEntrega': item.diasEntrega,
                                        'cantidadAgregados' : 1,
                                        'acabadoId' : acabado,
                                        'saborId' : sabor,
                                        'rellenoId1' : relleno1,
                                        'rellenoId2' : relleno2,
                                        'pedidoId' : ''
                                        })
                
                                        let precio = (this.state.precioCompra + parseInt(preciod));
                
                                        if(precio >34){

                                            this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                        }

                                        localStorage.setItem('precioTotal',precio);
                
                                        this.setState({precioCompra : precio})

                                        let totalAgregados = 0;

                                        for(let i=0; i < data.length; i++){
                                            
                                            totalAgregados = data[i].cantidadAgregados + totalAgregados
                                        }

                                        this.props.contadorProductos(totalAgregados);
                                });

                                localStorage.setItem('dataProducto', JSON.stringify(data));
                
                                this.setState({
                                    dataProducto : data,
                                    productoId: 0
                                })
                            })

                        }else{
                            for(let i=0; i < data.length; i++){

                                if (data[i].productoDetalleId === productoDetalleId && data[i].saborId === sabor) {
                                    
                                    let cantidad = parseInt(data[i].cantidadAgregados) + 1;
                
                                    data[i].cantidadAgregados = cantidad;
                                    data[i].precio = preciod;

                                    let precio = this.state.precioCompra + (parseInt(this.props.state.preciod));

                                    if(precio > 34){

                                        this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                    }

                                    localStorage.setItem('precioTotal',precio);
                                    this.setState({precioCompra : precio})
                                    
                                    localStorage.setItem('dataProducto', JSON.stringify(data));

                                    let totalAgregados = 0;

                                    for(let i=0; i < data.length; i++){
                                        
                                        totalAgregados = data[i].cantidadAgregados + totalAgregados
                                    }

                                    this.props.contadorProductos(totalAgregados);
                                }
                            }
                        }
                    }else{


                        const { productoDetalleId, cantidad, preciod, medida} = this.props.state
                        
                        if(medida === 3){
                           
                            let existe = this.validarExisteMedida(productoDetalleId);

                            if( existe === false){

                                localStorage.setItem('varDisplay',this.props.state.varDisplay);
                                localStorage.setItem('estadoCesta', this.props.state.estadoCesta);

                                this.setState({
                                    varDisplay : localStorage.getItem('varDisplay'),
                                    estadoCesta : localStorage.getItem('estadoCesta')
                                });

                                axios.get(`${Common.urlAPI}/Producto/DetalleProducto/${this.props.state.producto}`,config).then((response) => {

                                    response.data.detail.map(item => {
                    
                                            data.push({
                                            'productoId': item.productoId,
                                            'productoDetalleId' : productoDetalleId,
                                            'nombre': item.nombre,
                                            'imagen' : item.imagen,
                                            'descripcion' : item.descripcion,
                                            'clasificacion' : item.clasificacion,
                                            'cantidad' : cantidad,
                                            'medida' : medida,
                                            'precio' : preciod,
                                            'diasEntrega': item.diasEntrega,
                                            'cantidadAgregados' : 1,
                                            'acabadoId' : '',
                                            'saborId' : '',
                                            'rellenoId1' : '',
                                            'rellenoId2' : '',
                                            'pedidoId' : ''
                                            })
                    
                                            let precio = (this.state.precioCompra + parseInt(preciod));
                    
                                            if(precio > 34){

                                                this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                            }

                                            localStorage.setItem('precioTotal',precio);
                    
                                            this.setState({precioCompra : precio});

                                            let totalAgregados = 0;

                                            for(let i=0; i < data.length; i++){
                                                
                                                totalAgregados = data[i].cantidadAgregados + totalAgregados
                                            }

                                            this.props.contadorProductos(totalAgregados);
                                    });

                                    localStorage.setItem('dataProducto', JSON.stringify(data));
                    
                                    this.setState({
                                        dataProducto : data,
                                        productoId: 0
                                    })
                                })

                            }else{
                                for(let i=0; i < data.length; i++){

                                    if (data[i].productoDetalleId === productoDetalleId) {
                                        
                                        let cantidad = parseInt(data[i].cantidadAgregados) + 1;
                    
                                        data[i].cantidadAgregados = cantidad;
                                        data[i].precio = preciod;
        
                                        let precio = this.state.precioCompra + (parseInt(this.props.state.preciod));
        
                                        if(precio > 34){
        
                                            this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                        }
        
                                        localStorage.setItem('precioTotal',precio);
                                        this.setState({precioCompra : precio})
                                        
                                        localStorage.setItem('dataProducto', JSON.stringify(data));

                                        let totalAgregados = 0;

                                        for(let i=0; i < data.length; i++){
                                            
                                            totalAgregados = data[i].cantidadAgregados + totalAgregados
                                        }

                                        this.props.contadorProductos(totalAgregados);
                                    }
                                }
                            }
                        }else{

                            const { productoDetalleId, cantidad, preciod, categoria} = this.props.state

                            if(categoria === 1){

                                let existe = this.validarExisteMedida(productoDetalleId);

                                if( existe === false){

                                    localStorage.setItem('varDisplay',this.props.state.varDisplay);
                                    localStorage.setItem('estadoCesta', this.props.state.estadoCesta);
    
                                    this.setState({
                                        varDisplay : localStorage.getItem('varDisplay'),
                                        estadoCesta : localStorage.getItem('estadoCesta')
                                    });
    
                                    axios.get(`${Common.urlAPI}/Producto/DetalleProducto/${this.props.state.producto}`,config).then((response) => {
    
                                        response.data.detail.map(item => {
                        
                                                data.push({
                                                'productoId': item.productoId,
                                                'productoDetalleId' : productoDetalleId,
                                                'nombre': item.nombre,
                                                'imagen' : item.imagen,
                                                'descripcion' : item.descripcion,
                                                'clasificacion' : item.clasificacion,
                                                'cantidad' : cantidad,
                                                'medida' : medida,
                                                'precio' : preciod,
                                                'diasEntrega': item.diasEntrega,
                                                'cantidadAgregados' : 1,
                                                'acabadoId' : '',
                                                'saborId' : '',
                                                'rellenoId1' : '',
                                                'rellenoId2' : '',
                                                'pedidoId' : ''
                                                })
                        
                                                let precio = (this.state.precioCompra + parseInt(preciod));
                        
                                                if(precio > 34){
    
                                                    this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                                }
    
                                                localStorage.setItem('precioTotal',precio);
                        
                                                this.setState({precioCompra : precio});
    
                                                let totalAgregados = 0;
    
                                                for(let i=0; i < data.length; i++){
                                                    
                                                    totalAgregados = data[i].cantidadAgregados + totalAgregados
                                                }
    
                                                this.props.contadorProductos(totalAgregados);
                                        });
    
                                        localStorage.setItem('dataProducto', JSON.stringify(data));
                        
                                        this.setState({
                                            dataProducto : data,
                                            productoId: 0
                                        })
                                    })
                                  
                                }else{

                                    for(let i=0; i < data.length; i++){
    
                                        if (data[i].productoDetalleId === productoDetalleId) {
                                            
                                            let cantidad = parseInt(data[i].cantidadAgregados) + 1;
                        
                                            data[i].cantidadAgregados = cantidad;
                                            data[i].precio = preciod;
            
                                            let precio = this.state.precioCompra + (parseInt(this.props.state.preciod));
            
                                            if(precio > 34){
            
                                                this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                            }
            
                                            localStorage.setItem('precioTotal',precio);
                                            this.setState({precioCompra : precio})
                                            
                                            localStorage.setItem('dataProducto', JSON.stringify(data));
    
                                            let totalAgregados = 0;
    
                                            for(let i=0; i < data.length; i++){
                                                
                                                totalAgregados = data[i].cantidadAgregados + totalAgregados
                                            }
    
                                            this.props.contadorProductos(totalAgregados);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                
            }else{

                if(this.props.state.clasificacion !== 2){

                    for(let i=0; i < data.length; i++){

                        if (data[i].productoId === this.props.state.producto) {
                            
                            let cantidad = parseInt(data[i].cantidadAgregados) + 1;
        
                            data[i].cantidadAgregados = cantidad;
        
                            let precio = this.state.precioCompra + (parseInt(data[i].precio));
    
                            if(precio > 34){
    
                                this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                            }
    
                            localStorage.setItem('precioTotal',precio);
                            this.setState({precioCompra : precio})
                            
                            localStorage.setItem('dataProducto', JSON.stringify(data));

                            let totalAgregados = 0;

                                    for(let i=0; i < data.length; i++){
                                        
                                        totalAgregados = data[i].cantidadAgregados + totalAgregados
                                    }

                                    this.props.contadorProductos(totalAgregados);
                        }
                    }

                }else{

                    const { productoDetalleId, cantidad, preciod, acabado, sabor, relleno1, relleno2} = this.props.state


                    if(cantidad !== '' && preciod !== '', acabado !== '', sabor !== '' && relleno1 !== ''){

                        let existe = this.validarExisteDetalle(productoDetalleId, sabor);

                        if( existe === false){

                            localStorage.setItem('varDisplay',this.props.state.varDisplay);
                            localStorage.setItem('estadoCesta', this.props.state.estadoCesta);

                            this.setState({
                                varDisplay : localStorage.getItem('varDisplay'),
                                estadoCesta : localStorage.getItem('estadoCesta')
                            });

                            axios.get(`${Common.urlAPI}/Producto/DetalleProducto/${this.props.state.producto}`,config).then((response) => {

                                response.data.detail.map(item => {
                
                                        data.push({
                                        'productoId': item.productoId,
                                        'productoDetalleId' : productoDetalleId,
                                        'nombre': item.nombre,
                                        'imagen' : item.imagen,
                                        'descripcion' : item.descripcion,
                                        'clasificacion' : item.clasificacion,
                                        'cantidad' : cantidad,
                                        'medida' : '',
                                        'precio' : preciod,
                                        'diasEntrega': item.diasEntrega,
                                        'cantidadAgregados' : 1,
                                        'acabadoId' : acabado,
                                        'saborId' : sabor,
                                        'rellenoId1' : relleno1,
                                        'rellenoId2' : relleno2,
                                        'pedidoId' : ''
                                        })
                
                                        let precio = (this.state.precioCompra + parseInt(preciod));
                
                                        if(precio > 34){

                                            this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                        }

                                        localStorage.setItem('precioTotal',precio);
                
                                        this.setState({precioCompra : precio});

                                        let totalAgregados = 0;

                                        for(let i=0; i < data.length; i++){
                                            
                                            totalAgregados = data[i].cantidadAgregados + totalAgregados
                                        }

                                        this.props.contadorProductos(totalAgregados);
                                });

                                localStorage.setItem('dataProducto', JSON.stringify(data));
                
                                this.setState({
                                    dataProducto : data,
                                    productoId: 0
                                })
                            })

                        }else{
                            for(let i=0; i < data.length; i++){

                                if (data[i].productoDetalleId === productoDetalleId && data[i].saborId === sabor) {
                                    
                                    let cantidad = parseInt(data[i].cantidadAgregados) + 1;
                
                                    data[i].cantidadAgregados = cantidad;
                                    data[i].precio = preciod;

                                    let precio = this.state.precioCompra + (parseInt(this.props.state.preciod));

                                    if(precio > 34){

                                        this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                    }

                                    localStorage.setItem('precioTotal',precio);
                                    this.setState({precioCompra : precio})
                                    
                                    localStorage.setItem('dataProducto', JSON.stringify(data));

                                    let totalAgregados = 0;

                                    for(let i=0; i < data.length; i++){
                                        
                                        totalAgregados = data[i].cantidadAgregados + totalAgregados
                                    }

                                    this.props.contadorProductos(totalAgregados);
                                }
                            }
                        }
                    }else{
                        for(let i=0; i < data.length; i++){

                            if (data[i].productoDetalleId === productoDetalleId) {
                                
                                let cantidad = parseInt(data[i].cantidadAgregados) + 1;
            
                                data[i].cantidadAgregados = cantidad;
                                data[i].precio = preciod;

                                let precio = this.state.precioCompra + (parseInt(this.props.state.preciod));

                                if(precio > 34){

                                    this.setState({activarBoton : false, opacity : '1', pedidominimo : '¡Listo para la compra!', displayPrecio : "none"})
                                }

                                localStorage.setItem('precioTotal',precio);
                                this.setState({precioCompra : precio})
                                
                                localStorage.setItem('dataProducto', JSON.stringify(data));

                                let totalAgregados = 0;

                                    for(let i=0; i < data.length; i++){
                                        
                                        totalAgregados = data[i].cantidadAgregados + totalAgregados
                                    }

                                    this.props.contadorProductos(totalAgregados);
                            }
                        }

                    }
                }
                
            }
        }
    }

    validarExiste = (productoId) =>{

        let resultado = false;

        if(this.state.dataProducto !== null){

            for(let i=0; i < this.state.dataProducto.length; i++){

                if (this.state.dataProducto[i].productoId === productoId) {

                    resultado = true;

                }
            }
        }
        return resultado;
    }

    validarExisteDetalle = (productoDetalleId, sabor) =>{

        let resultado = false;

        const { dataProducto} = this.state;

        if(dataProducto !== null){

            for(let i=0; i < dataProducto.length; i++){

                if (dataProducto[i].productoDetalleId === productoDetalleId && dataProducto[i].saborId === sabor ) {

                    resultado = true;

                }
            }
        }

        return resultado;
    }

    validarExisteMedida = (productoDetalleId) =>{

        let resultado = false;

        if(this.state.dataProducto !== null){

            for(let i=0; i < this.state.dataProducto.length; i++){

                if (this.state.dataProducto[i].productoDetalleId === productoDetalleId) {

                    resultado = true;

                }
            }
        }
        return resultado;
    }


    render() {
        
      return (
        <Grid fluid>
            <Row>
                <Col md={12} className="bg-tupedido">
                    <h1>Tu pedido</h1>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="bg-agregados">
                    <h1>{(this.state.dataProducto !== null)? this.state.dataProducto.length : 0}</h1><h1>Productos agregados</h1><Link onClick={(e) => this.vaciarCarrito(e)} >vaciar carrito</Link>
                </Col>
            </Row>
            <Row>
                <Col className="itemsAgregados" md={12} >
                    {
                        (this.state.estadoCesta !== false && this.state.dataProducto !== null)?

                         this.state.dataProducto.map( item => {
                            return(
                                <div class="sc-1qw9vvs-1 ienLgJ" id="">
                                <div class="sc-gzVnrw sc-1qw9vvs-0 hrKjEa sc-tilXH gMZCjV" style={{flexShrink: '0'}}>
                                <a class="sc-jnlKLf hspvSP">
                                <img alt="Carrot Cake " src={Common.urlIMG + item.imagen} height="72" width="80" class="sc-kAzzGY fIrlhz" style={{minHeight: '72px'}} />
                                </a>
                                <div class="sc-hEsumM jGvQok">
                                <a color="black" class="sc-jzJRlG fDtcau">{item.nombre}</a>
                                {
                                    item.clasificacion === 1?
                                        item.cantidad > 1?
                                        <p class="sc-ifAKCX hhHUu">{item.cantidad} Unidades</p>
                                        :
                                        <p class="sc-ifAKCX hhHUu">{item.cantidad} Unidad</p>
                                    :
                                    (item.medida === 3)?
                                        <p class="sc-ifAKCX hhHUu">{item.cantidad} Caja</p>
                                    :
                                    <p class="sc-ifAKCX hhHUu">{item.cantidad} Porciones</p>
                                }
                                <div class="sc-gisBJw ippVNs" style={{visibility: 'visible'}}>
                                <div class="sc-kjoXOD gjthul">
                                <span color="error" class="sc-ifAKCX cpfXQe">S/ {item.precio}.00</span>
                                </div>
                                </div>
                                </div>
                                <div class="sc-fYxtnH dbiHjz">
                                <div class="sc-jWBwVP hNlSzY">
                                <button id="btnCantProducto-' + datosBarraProductos[i].IdProducto + '" color="secondary"class="sc-htpNat huaAfC">{item.cantidadAgregados}</button>
                                </div>
                                {
                                     item.clasificacion === 1?
                                     <Link  onClick={(e) => this.eliminarProducto(e,item.productoId)} ><span class="sc-ifAKCX bcBwG" style={{cursor: 'pointer'}}>eliminar</span></Link>
                                    :
                                    <Link  onClick={(e) => this.eliminarProductoDetalle(e,item.productoDetalleId, item.Sabor)} ><span class="sc-ifAKCX bcBwG" style={{cursor: 'pointer'}}>eliminar</span></Link>
                                }
                                </div>
                                </div>
                                </div>
                            )
                        })
                        :

                        undefined
                    }
                    
                </Col>
            </Row>
            <Row>
                <Col md={12} className="bg-itemsproduc" style={{display : this.state.varDisplay}} >
                    <img src={commerce} alt="" />
                    <h1>Tu carrito esta vacío</h1>
                    <p>Parece que aún no has elegido que comprar...</p>
                    <button type="button" className="btn btn-warning">Ver productos</button>
                    
                </Col>
            </Row>
            <Row>
                <Col md={12} className="bg-piecompra">
                    <p>{this.state.pedidominimo}</p>&nbsp;&nbsp;
                    <h1 style={{display: this.state.displayPrecio}}> S/. 35.00</h1>
                    <div className="progress">
                        <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <button className="btn-procesarcompra" style={{opacity : this.state.opacity}} onClick={e => this.ProcesarCompra(e)} disabled={this.state.activarBoton}>
                        COMPRAR
                        <span>S/. {this.state.precioCompra}.00</span>
                    </button>
                </Col>
            </Row>
        </Grid>
      )
    }
  }  