import React, {Component} from 'react';
import Common from "./Common";
import axios from "axios";
import { Link } from 'react-router-dom';

import Box from '../images/box.png'


const config = {
    headers: { Authorization: Common.tokenAPI}
};



export default class Datos extends Component{

    constructor(props) {
        super(props);
    
        this.state = { 
            dataPedido : '',
            userId : localStorage.getItem('id'),
            sesion : localStorage.getItem('sesion'),
            estadoPedido : 0,
            dataDetallePedido : []
        };
    }

    async componentDidMount(){

        if(this.state.sesion !== '1'){

            window.location.href = Common.urlRedirect;

        }else{
            await axios.get(`${Common.urlAPI}/Pedido/Listar/${this.state.userId}`,config).then((response) => {

                let source =  response.data.detail.map(item => {
                    return {
                        pedidoId: item.pedidoId,
                        total: item.total,
                        estadoPedidoNombre : item.estadoPedidoNombre,
                        fechaPedido : item.fechaPedido
                    };
                });
    
    
                this.setState({
                    dataPedido : source
                })
            })
        }
        

    }

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [day,mnth,date.getFullYear()].join("/");
    }

    convertEntrega(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }


    cambiarOpcion(e, opcion, PedidoId){
       e.preventDefault();

       if(opcion === 1){
           this.setState({
               estadoPedido : 0
           })
       }else{

        axios.get(`${Common.urlAPI}/DetallePedido/Listar/${PedidoId}`,config).then((response) => {

            let source =  response.data.detail.map(item => {
                return {
                    pedidoId2: item.pedidoId2,
                    productoDetalleId2: item.productoDetalleId2,
                    fechaRegistro : item.fechaRegistro,
                    nombreProducto : item.nombreProducto,
                    imagen : item.imagen,
                    sabor : item.sabor,
                    relleno1: item.relleno1,
                    relleno2 : item.relleno2,
                    cantidad : item.cantidad,
                    precioUnitario : item.precioUnitario,
                    cantidadPedido: item.cantidadPedido,
                    direccionEnvio : item.apellidos,
                    fechaEntrega : item.fechaEntrega,
                    tipoComprobante : item.tipoComprobante,
                    tipoPago : item.tipoPago,
                    subtotal : item.subtotal,
                    delivery : item.delivery,
                    total : item.total,
                    metodoEnvio : item.metodoEnvioNombre
                };
            });

            this.setState({
                dataDetallePedido : source,
                estadoPedido : 1
            })
        })

        
       }
    }

    render(){
        return(
            (this.state.estadoPedido === 0)?
                <div className="container-fluid bg-datos">
                <h1>Mis Pedidos</h1>
                {
                    (this.state.dataPedido.length !== 0)?

                     this.state.dataPedido.map(item => {
                         return (
                            <div className="row">
                            <div className="col-md-12 bg-pedido">
                                 <div className="row">
                                    <div className="col-6 col-md-6">
                                        <Link onClick={(e)=> this.cambiarOpcion(e,0,item.pedidoId)} style={{fontSize : "16px", fontFamily: "Open Sans, sans-serif", color: "rgb(51, 51, 51)",fontWeight: "600"}}>P00{item.pedidoId} <i className="fas fa-angle-right" ></i></Link>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <div>
                                            {
                                                (item.estadoPedidoNombre === 'CONFIRMADO')?
                                                <span className="badge badge-primary" style={{fontSize : "14px", fontFamily: "Open Sans, sans-serif", float: "right"}}>{item.estadoPedidoNombre}</span>
                                                :
                                                (item.estadoPedidoNombre === 'EN PROCESO')?
                                                <span className="badge badge-warning" style={{fontSize : "14px", fontFamily: "Open Sans, sans-serif", float: "right"}}>{item.estadoPedidoNombre}</span>
                                                :
                                                (item.estadoPedidoNombre === 'ENTREGADO')?
                                                <span className="badge badge-success" style={{fontSize : "14px", fontFamily: "Open Sans, sans-serif", float: "right"}}>{item.estadoPedidoNombre}</span>
                                                :
                                                <span className="badge badge-danger" style={{fontSize : "14px", fontFamily: "Open Sans, sans-serif", float: "right"}}>{item.estadoPedidoNombre}</span>
                                            }
                                           
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <p style={{fontSize : "12px", fontFamily: "Open Sans, sans-serif"}}>Comprado el {this.convert(item.fechaPedido)}</p>
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <div>
                                           <p style={{fontSize : "16px", fontFamily: "Open Sans, sans-serif",fontWeight: "600", float: "right", color: "red"}}>S/ {item.total}.00</p> 
                                        </div>
                                    </div>
                                 </div>
                            </div>
                       </div>
                         )
                     })

                     :

                    <div className="row">
                     <div className="col-md-12 bg-pedido-none">
                         <h3>No hay Pedidos realizados</h3>
                         <img src={Box} alt="box"/>
                     </div>
                    </div>
                }
                
            </div>
            :

            <div className="container-fluid bg-detallepedido">
                <div className="row">
                    <div className="col-12 border-pedido">
                        <div className="sc-1gqi0ex-3 dOIdIY"><a onClick={(e)=> this.cambiarOpcion(e,1)} ><svg fill="#333333" viewBox="0 0 24 24" type="arrow-left" color="black" class="sc-bxivhb hXLYRj"><path d="M9.328 19.038L2 11.71l7.328-7.327a1.364 1.364 0 011.928 1.928L7.22 10.347h13.416a1.364 1.364 0 010 2.727H7.221l4.035 4.036a1.363 1.363 0 01-1.928 1.928z"></path></svg></a>
                            <p className="sc-ifAKCX iGWcpD">P00{this.state.dataDetallePedido[0].pedidoId2}</p>
                        </div>
                    </div>
                    <div className="col-12 border-pedido">
                        <h5 className="sc-ifAKCX haSlZv">Productos</h5>
                    </div>
                    <div className="col-12 border-pedido">
                        {
                            this.state.dataDetallePedido.map(item => {
                            return(
                            <article>
                                <div class="sc-gzVnrw sc-72ip1c-0 fAFyql sc-1q0j0ut-1 gKZqRR">
                                    <img alt="" src={Common.urlIMG+item.imagen} class="sc-kAzzGY fIrlhz" />
                                    <div class="sc-1q0j0ut-0 hkXKYE">
                                        <p class="sc-ifAKCX drzNke">{item.nombreProducto}</p>
                                        <p class="sc-ifAKCX hhHUu">
                                          {
                                              
                                              (item.sabor !== '' || item.relleno1 !== '' || item.relleno2 !== '')?
                                                item.sabor+','+item.relleno1+','+item.relleno2
                                              :
                                               undefined
                               
                                          }
                                        </p>
                                        <div class="sc-1jygmc5-0 efBhbb"><div class="sc-1jygmc5-1 hEZMGZ">
                                            <span color="black" class="sc-ifAKCX bcBwG">S/ {item.precioUnitario}.<span color="black" class="sc-ifAKCX bcBwG">00</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sc-1q0j0ut-2 jtaakn"><p class="sc-ifAKCX haSlZv">x {item.cantidadPedido}</p>
                                    <div class="sc-1q0j0ut-3 kKHVwl sc-1jygmc5-0 efBhbb">
                                        <div class="sc-1jygmc5-1 hEZMGZ">
                                            <span color="error" class="sc-ifAKCX cpfXQe">S/ {parseInt(item.precioUnitario) * parseInt(item.cantidadPedido)}.<span color="error" class="sc-ifAKCX cpfXQe">00</span></span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </article>
                            )
                            })
                        }
                        
                    </div>
                    <div className="col-12 border-pedido" style={{marginTop : "25px"}}>
                        <h5 className="sc-ifAKCX haSlZv">Datos de entrega</h5>
                    </div>
                    <div className="col-12 border-pedido">
                        <div class="sc-kpOJdX eyYvmH"><svg fill="#333333" viewBox="0 0 24 24" color="black" type="truck" class="sc-bxivhb iAiqgo"><path d="M14.197 15.645a3.181 3.181 0 00-3.178 3.177A3.181 3.181 0 0014.197 22a3.181 3.181 0 003.177-3.178 3.182 3.182 0 00-3.177-3.177zm0 4.112a.935.935 0 11.001-1.87.935.935 0 01-.001 1.87z M13.73 2c.288.069.48.162.574.28l6.099 7.63c.112.14.174.317.174.501v7.629c0 .432-.335.782-.748.782h-.792a.756.756 0 01-.745-.663c-.294-2.352-2.087-3.812-4.257-3.812-2.169 0-3.962 1.46-4.256 3.812a.756.756 0 01-.745.663H4a1 1 0 01-1-1V3a1 1 0 011-1h9.73zM8.989 8.26c0 .432.335.782.748.782L15.886 9c.634 0 .905-.49.5-1l-3-3.5a3.008 3.008 0 00-.222-.237.938.938 0 00-.662-.263h-2.324a.868.868 0 00-.53.17c-.264.2-.652.545-.652.83l-.008 3.26z"></path></svg>
                        <span class="sc-ifAKCX drzNke">
                            {
                                this.state.dataDetallePedido[0].metodoEnvio === '1' ?
                                
                                 'Entrega a domicilio'

                                 :

                                  'Recojo en Tienda'
                            }
                        </span>   
                        </div>
                        <div class="sc-18s8nf4-0 fMGRjm">
                            <span class="sc-iwsKbI dRZuUw">
                                <label class="sc-ifAKCX kAcbIt">Fecha de entrega</label>
                                <label class="sc-ifAKCX drzNke">{this.state.dataDetallePedido[0].fechaEntrega}</label>
                            </span>
                        </div>
                    </div>
                    <div className="col-12 border-pedido" style={{marginTop : "25px"}}>
                        <h5 className="sc-ifAKCX haSlZv">Comprobante de pago</h5>
                    </div>
                    <div className="col-12 border-pedido">
                        <div class="sc-kpOJdX eyYvmH"><svg fill="#333333" viewBox="0 0 24 24" color="black" type="ticket" class="sc-bxivhb iAiqgo"><path d="M16.967 2L15 3.333 13 2l-2 1.333L9 2 7 3.333 5 2v18l2.033 2 2-1.333 2 1.333 2-1.333 2 1.333 2-1.333 2 1.333V4l-2.066-2zm-5.3 14.667h-4a.667.667 0 010-1.334h4a.667.667 0 010 1.334zM13 14H7.667a.666.666 0 110-1.333H13A.667.667 0 1113 14zm-6-3.333A.667.667 0 017.667 10h4a.667.667 0 110 1.333h-4A.667.667 0 017 10.667zm6-2H7.667a.667.667 0 010-1.334H13a.667.667 0 110 1.334zm3.333 8h-.666a.667.667 0 010-1.334h.666a.667.667 0 010 1.334zm0-2.667h-.666a.666.666 0 110-1.333h.666a.667.667 0 110 1.333zm0-2.667h-.666a.667.667 0 110-1.333h.666a.666.666 0 110 1.333zm0-2.666h-.666a.667.667 0 110-1.334h.666a.667.667 0 110 1.334z"></path></svg>
                            <span class="sc-ifAKCX drzNke">{this.state.dataDetallePedido[0].tipoComprobante}</span>   
                        </div>
                    </div>
                    <div className="col-12 border-pedido" style={{marginTop : "25px"}}>
                        <h5 className="sc-ifAKCX haSlZv">Metodo de pago</h5>
                    </div>
                    <div className="col-12 border-pedido">
                        <div class="sc-kpOJdX eyYvmH"><svg fill="#333333" viewBox="0 0 24 24" color="black" type="payment-method" class="sc-bxivhb iAiqgo"><path d="M12 2a10 10 0 100 20 10 10 0 000-20zm.667 15.5v1.167a.667.667 0 01-1.334 0V17.5a3.333 3.333 0 01-2.666-3.267.667.667 0 111.333 0 2 2 0 002 2c1.167 0 2-.733 2-1.766 0-1.034-.733-1.467-2.133-1.8-2.634-.6-3.2-2-3.2-3.1A3.1 3.1 0 0111.333 6.5V5.333a.667.667 0 011.334 0V6.5A3.333 3.333 0 0115.3 9.2a.667.667 0 01-1.3.233 2 2 0 00-1.967-1.666c-1.166 0-2 .733-2 1.766 0 .334 0 1.3 2.134 1.8 2.766.634 3.2 2.2 3.2 3.1a3.1 3.1 0 01-2.7 3.067z"></path></svg>
                            <span class="sc-ifAKCX drzNke">{this.state.dataDetallePedido[0].tipoPago}</span>   
                        </div>
                    </div>
                    <div className="col-12 border-pedido" style={{marginTop : "25px"}}>
                        <h5 className="sc-ifAKCX haSlZv">Detalle de precio</h5>
                    </div>
                    <div className="col-12 border-pedido">
                        <div class="rc27rk-1 iDlZEf">
                            <span class="sc-ifAKCX drzNke">Productos</span>
                            <div class="sc-1jygmc5-0 efBhbb">
                                <div class="sc-1jygmc5-1 hEZMGZ">
                                    <span color="black" class="sc-ifAKCX drzNke">S/ {this.state.dataDetallePedido[0].subtotal}.<span color="black" class="sc-ifAKCX drzNke">00</span></span>
                                </div>
                            </div>
                        </div>
                        <div class="rc27rk-1 iDlZEf">
                            <span class="sc-ifAKCX drzNke">Delivery</span>
                            <div class="sc-1jygmc5-0 efBhbb">
                                <div class="sc-1jygmc5-1 hEZMGZ">
                                    <span color="black" class="sc-ifAKCX drzNke">S/ {this.state.dataDetallePedido[0].delivery}.<span color="black" class="sc-ifAKCX drzNke">00</span></span>
                                </div>
                            </div>
                        </div>
                        <div class="rc27rk-1 iDlZEf">
                            <span class="sc-ifAKCX drzNke">Descuento</span>
                            <div class="sc-1jygmc5-0 efBhbb">
                                <div class="sc-1jygmc5-1 hEZMGZ">
                                    <span color="black" class="sc-ifAKCX drzNke">S/ 00.<span color="black" class="sc-ifAKCX drzNke">00</span></span>
                                </div>
                            </div>
                        </div>
                        <div class="rc27rk-1 iDlZEf">
                            <span class="sc-ifAKCX drzNke">Total</span>
                            <div class="sc-1jygmc5-0 efBhbb">
                                <div class="sc-1jygmc5-1 hEZMGZ">
                                    <span color="black" class="sc-ifAKCX drzNke">S/ {this.state.dataDetallePedido[0].total}.<span color="black" class="sc-ifAKCX drzNke">00</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}