import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import recuperar from '../images/recuperar.png'

export default class GraciasRecuperarContrasena extends Component{
    render(){
        return(

        <section className="col-md-12 col-lg-12 bg-section">
            <div className="container bg-gracias">
                <div className="container">
                    <img src={recuperar} alt="gracias" />
                    <h1>¡TE HEMOS ENVIADO TU CONTRASEÑA!</h1>
                    <p>Revisa tu correo para visualizar tu contraseña recuperada.</p>
                    <Link to={'/'}>Seguir Comprando</Link>
                </div>
            </div>
        </section>

        )
    }
}