import React, {Component} from 'react';
import {Sugar} from 'react-preloaders';
import Footer from './Footer'

export default class TerminosCondiciones extends Component{

    constructor(props) {
        super(props);
        
        this.state = { 
            loading: false
        };

        
    }

    render(){
        return(

        <section className="col-md-12 col-lg-12 bg-section">
            <div className="container">
           <div className="row">
                <div class="col-lg-12 cb-terminos">
                    <h1>Términos y Condiciones</h1>
                    <br/>
                     <h3>CONDICIONES DE USO DEL SITIO WEB</h3>
                     <h4>Términos de Uso del Sitio Web</h4>
                     <p style={{marginLeft: "20px"}} >Lea atentamente estos términos y condiciones ya que contienen información importante sobre sus derechos y obligaciones al utilizar este sitio web (el «sitio web»).</p>
                     <p style={{marginLeft: "20px"}}>El sitio web es propiedad de EXPERIENCE GROUP SAC. (comercializada como Dolce Salato), registrada en la Superintendencia de Administración Tributaria (SUNAT) de Perú, con número de RUC: 20600944682, domiciliada en Jirón Malaga 378 Urb. La Capilla, La Molina, Provincia y Departamento de Lima – Perú.</p>
                     <p style={{marginLeft: "20px"}}>El término “USTED”, se refiere al usuario o espectador de nuestro sitio web.
                        Al navegar o usar el sitio web, usted declara y acepta cumplir con estos términos y condiciones que, junto con nuestra política de privacidad, establece los parámetros de nuestra relación con usted, respecto al uso de nuestro Sitio Web.
                    </p>
                    <ol>
                      <li value="1">Acceso
                      <br/><br/>
                        <p>Podrá acceder y navegar por todo nuestro sitio web, sin tener que registrar ningún tipo de información, pues dejamos constancia que velamos por su seguridad y en pro de no generar ningún tipo de violación a su privacidad, nosotros no solicitamos ningún tipo de información personal. Sin embargo, ciertas áreas de este sitio web pueden ser accesibles si es un usuario registrado, registro sencillo que no involucra ningún tipo de información financiera de parte usted.</p>
                        <p>Usted es responsable de hacer todos los arreglos necesarios para que tenga acceso a nuestro Sitio Web. Usted, también es responsable de asegurar que todas las personas que acceden al Sitio Web a través de su conexión a Internet y utilizando su usuario, son conscientes de estos términos, y que cumplan con ellos.</p>
                        <p>Hacemos esfuerzos razonables para asegurar que este Sitio Web esté disponible para ver y utilizar las 24 horas del día durante todo el año sin embargo, esto no está garantizado. El sitio web puede estar temporalmente no disponible en cualquier momento debido a fallas de nuestro servidor o de nuestros sistemas u otros problemas técnicos, atribuibles a la tecnología y nuestros proveedores; razones que están fuera de nuestro control. Mencionado esto último, recalcamos y confirmamos nuestro afán por brindarle el mejor servicio de nuestra parte, por ello, siempre que sea posible intentaremos darle una advertencia anticipada de los problemas de mantenimiento, pero no estaremos obligados a hacerlo.</p>
                      </li>
                      <li>Registrarse en el Sitio Web
                      <br/><br/>
                          <p>Al registrarse en el Sitio Web debe elegir un nombre de usuario y una contraseña. Usted es responsable de todas las acciones tomadas bajo su nombre de usuario y contraseña.</p>
                          <p>Al registrarse en el sitio web, usted se compromete a:</p>
                          <ul>
                            <li type="disc">Que todos los datos que nos proporcione con el fin de registrarse en el sitio web sean verdaderos, exactos, actuales y completos en todos los aspectos.</li>
                            <li type="disc">Usted deberá informarnos de cualquier cambio en la información proporcionada en su registro.</li>
                            <li type="disc">Ser honesto en su declaración de mayoría de edad, es decir tiene que ser mayor de 18 años y en caso no lo fuere declara contar con el permiso de un padre o tutor para registrarse en el Sitio Web junto, con y bajo su supervisión, no haciéndonos responsables por la falta a esta obligación y/o falsedad en su declaración.</li>
                            <li type="disc">Usar nuestro Sitio Web utilizando su propio nombre de usuario y contraseña.</li>
                            <li type="disc">Hacer todo lo posible para mantener su contraseña a salvo.</li>
                            <li type="disc">No divulgar su contraseña a nadie.</li>
                            <li type="disc">A cambiar su contraseña inmediatamente después de descubrir que se ha comprometido su privacidad.</li>
                            <li type="disc">No transferir ni vender su nombre de usuario o contraseña, ni permitir, directa o indirectamente el uso de la misma a terceros, bajo su responsabilidad</li>
                            <li type="disc">Usted nos autoriza a intercambiar información con su nombre, dirección y otra información personal proporcionada por usted (incluida la información actualizada), para obtener información de terceros sobre usted, incluyendo, pero no limitado a, informes de crédito, con el único fin de autenticar su identidad.</li>               
                           </ul>
                           <br/>
                      </li>
                      <li>Elegibilidad para comprar en el Sitio Web
                      <br/><br/>
                          <p>Para ser elegible para comprar los Bienes en este Sitio Web y celebrar legalmente contratos con nosotros, debe:</p>
                          <ul>
                              <li type="disc">Tener 18 años de edad o más.</li>
                              <li type="disc">Ser legalmente capaz de celebrar un contrato y no encontrarse incapacitado, teniendo en cuenta los factores y presupuestos que establece el Código Civil Peruano.</li>
                              <li type="disc">Brindar la dirección exacta y facilitar detalles exactos para lograr una entrega eficiente en la entrega de alguna compra realizada por medio de nuestro sitio web en Lima.</li>
                              <li type="disc">Si usted es menor de 18 años, sólo puede usar el Sitio Web con, y bajo la supervisión de, un padre o apoderado.</li>
                              <li type="disc">Si no califica o no está de acuerdo con nuestras condiciones de uso, no debe usar nuestro sitio web.</li>
                          </ul>
                          <br />
                      </li>
                      <li>Propiedad Intelectual
                      <br/><br/>
                          <p>El contenido del Sitio Web está protegido por derechos de autor (incluyendo derechos de autor de diseño), marcas comerciales, patentes, bases de datos y otros derechos de propiedad intelectual y derechos de propiedad similares que incluyen (sin limitación), todos los derechos sobre materiales, obras, los códigos de fuente, los datos, la información técnica, las marcas comerciales, la buena voluntad, los modelos de utilidad de marcas de servicio, los derechos de topografía de semiconductores, el estilo o la presentación de los bienes o servicios, las creaciones, invenciones, mejoras o adiciones a una invención, los derechos morales y cualquier derecho similar en cualquier país (ya sea registrado o no registrado e incluyendo las solicitudes y el derecho a solicitarlos en cualquier parte del mundo).
                            Usted reconoce que los derechos de propiedad intelectual sobre el material y el contenido suministrado como parte del Sitio Web permanecerán con nosotros o con nuestros licenciantes.
                         </p>
                         <p>Usted puede descargar o copiar el contenido y otros elementos descargables que se muestran en el Sitio Web, sujeto a la condición de que el material sólo puede usarse para fines personales, de ninguna manera para fines comerciales. Está expresamente prohibido copiar o almacenar el contenido del Sitio Web para fines distintos del uso personal.</p>
                         <p>Usted puede recuperar y mostrar el contenido del sitio web en una pantalla de computadora, almacenar dicho contenido en forma electrónica en disco (pero no cualquier servidor u otro dispositivo de almacenamiento conectado a una red) o imprimir una copia de dicho contenido para su propia información personal – uso comercial, siempre y cuando mantenga intactos todos los derechos de autor y avisos de propiedad.</p>
                         <p>Usted no puede reproducir, modificar, copiar, distribuir o utilizar con fines comerciales cualquier material o contenido del Sitio Web, sin autorización expresa de nuestra parte.</p>
                         <p>Usted reconoce que cualquier otro uso del material y contenido de este sitio web está estrictamente prohibido y usted acepta (y acuerda no ayudar o facilitar cualquier tercero a) no copiar, reproducir, exhibir, distribuir, o crear obras derivadas de dicho material y contenido.</p>
                         <p>Dolce Salato, se reserva el derecho de iniciar una demanda de indemnización por daños y perjuicios, si en caso se incumpla o se atente contra la propiedad intelectual y demás descritas en el presente ítem, sin perjuicio de iniciar acciones legales de tipo penal contra quienes resulten responsables.</p>
                      </li>
                      <li>Renuncias
                      <br/><br/>
                      <p>Será responsabilidad del usuario, asegurarse de que la información sobre cualquier producto, servicio o información disponible a través del Sitio Web cumpla con sus requerimientos específicos, caso contrario será de su entera responsabilidad el no poner atención a dichas especificaciones.</p>
                      <p>No seremos responsables ante usted si el Sitio Web no está disponible en cualquier momento.</p>
                      <p>Tratamos de garantizar que la información disponible en el sitio web en cualquier momento sea exacta. Sin embargo, no garantizamos la exactitud o la integridad del material en este Sitio Web. Utilizamos todos los esfuerzos razonables para corregir los errores y las omisiones tan pronto como sea posible después de haber sido informados o notificados de ellos. No nos comprometemos a garantizar que dicho material es correcto o actualizado.</p>
                      <p>Todos los dibujos, imágenes, descriptivos y especificaciones en el sitio web tienen el único propósito de dar una descripción aproximada de su información general y sólo se debe utilizar como una guía.</p>
                      <p>Los precios y ofertas sólo son válidos en el momento de su publicación en el sitio web.</p>
                      <p>Todos los precios y descripciones sustituyen a todas las publicaciones anteriores.
                        Se hace todo lo posible para mantener actualizada la información relativa a la disponibilidad de existencias en el sitio web. Sin embargo, no garantizamos que este sea el caso, o que las existencias estarán siempre disponibles.
                      </p>
                      <p>El sitio web se proporciona en la forma en que está disponible y no ofrecemos ninguna garantía, ya sea expresa o implícita, estatutaria o de otro tipo (a menos que se indique expresamente en estos términos y condiciones o sea obligatoria por mandato de la Ley), en relación con la información, contenidos o servicios encontrados u ofrecidos en el Sitio Web, para cualquier propósito particular o cualquier transacción que se pueda realizar en, o a través del Sitio Web, incluyendo pero no limitado a, garantías implícitas de no infracción o incumplimiento, puntualidad, desempeño, seguridad, exactitud, condición o integridad, o cualquier garantía implícita que surja del curso de negociación, de uso o costumbre comercial.
                            No hacemos ninguna representación de ningún tipo, expresa o implícita, legal o de otra indole, con respecto a la disponibilidad del Sitio Web, así como sobre que esta será oportuna o libre de errores, como tampoco, que los defectos serán corregidos o que el Sitio Web o el servidor que lo hace disponible, lo haga libre de virus o errores.
                        </p>
                      <p>No seremos responsables ante usted por ninguna pérdida de contenido o información cargada o transmitida a través del Sitio Web y no asumimos ninguna responsabilidad de ningún tipo por cualquier pérdida o daño por la acción tomada en base a la información contenida en el Sitio Web.</p>
                      <p>No podemos garantizar y no podemos ser responsables de la seguridad o privacidad del Sitio Web y cualquier información proporcionada por usted.</p>
                      <p>Debe asumir el riesgo asociado con el uso de Internet. En particular, no seremos responsables de ningún daño o pérdida causado por un ataque distribuido de por agentes ajenos y/o usuarios o proveedores del servicio, troyanos de virus, gusanos, bombas lógicas, registradores de pulsaciones de teclas, spyware u otro material que sea malicioso o tecnológicamente dañino que pueda infectar su computadora, datos u otro material de propiedad como resultado del uso del sitio web o de la descarga de cualquier material publicado o vendido en el sitio web o de cualquier sitio web vinculado a él.</p>
                      <p>Nos reservamos el derecho de revelar dicha información a las autoridades correspondientes de ser necesario o exigido por Ley de ser necesario, en caso de incumplimiento de este acuerdo.</p>
                      </li>
                      <li>Uso del Sitio Web
                      <br/><br/>
                      <p>Usted está autorizado a utilizar el sitio web y el material contenido en él, siempre y cuando acepte estas condiciones y le brindemos la autorización correspondiente y de acuerdo con estos términos y condiciones, que pueden ser enmendados de vez en cuando sin previo aviso.</p>
                      <p>Proporcionamos el acceso y el uso del Sitio Web, haciendo declarando expresamente que excluimos todas las representaciones, garantías y condiciones a la extensión máxima permitida por la ley.</p>
                      </li>
                      <li>Nos reservamos derecho a:
                      <br/><br/>
                      <p>Hacer cambios en la información o materiales de este Sitio Web en cualquier momento y sin previo aviso.</p>
                      <p>Cambiar temporalmente o permanentemente, suspender o interrumpir cualquier aspecto del Sitio Web, incluyendo la disponibilidad de cualquier característica, información, base de datos o contenido, o restringir el acceso a partes o al sitio web entero sin previo aviso o responsabilidad hacia usted o a terceros.</p>
                      <p>Rechazar publicaciones del material del sitio web o eliminar material ya publicado en el sitio web.</p>
                      <p>Usted no puede usar el Sitio Web para cualquiera de los siguientes propósitos:</p>
                      <ul>
                      <li type="disc">Difusión de cualquier material ilegal, acosador, difamatorio, abusivo, amenazante, dañino, vulgar, obsceno o de otro modo objetable.</li>
                      <li type="disc">Transmitir material que fomente conductas que constituyan un delito penal, de lugar a responsabilidad civil o de otra índole, haciéndose responsable el usuario que lo propale, a ser sujeto de cualquier acción legal que se le interponga..</li>
                      <li type="disc">Violación de las leyes, reglamentos o códigos de prácticas locales, nacionales o internacionales aplicables.</li>
                      <li type="disc">Obtener acceso no autorizado a otros sistemas informáticos.</li>
                      <li type="disc">Interferir con el uso o disfrute por parte de otra persona del Sitio Web.</li>
                      <li type="disc">Violación de las leyes relativas al uso y disfrute de las redes públicas de telecomunicación.</li>
                      <li type="disc">Interferir, interrumpir o dañar redes o sitios web conectados al sitio web.</li>
                      <li type="disc">Utilización de minería de datos, robots o herramientas similares de recopilación, extracción y sustracción de datos (una vez o muchas veces), la reutilización de partes sustanciales del sitio web.</li>
                      <li type="disc">Transmitir o adquirir el envío de cualquier material publicitario o promocional no solicitado o no autorizado.</li>
                      <li type="disc">Crear y / o publicar su propia base de datos que incluya todas o partes sustanciales del Sitio Web.</li>
                      <li type="disc">Hacer, transmitir o almacenar copias electrónicas de materiales protegidos por derechos de autor sin el permiso previo del propietario. Además, no debe:</li>
                      <li type="disc">Introducir conscientemente virus, troyanos, gusanos, bombas lógicas, registradores de pulsaciones de teclas, spyware u otro material que sea malicioso o tecnológicamente dañino para el Sitio Web y sus usuarios.</li>
                      <li type="disc">Intentar obtener acceso no autorizado al Sitio Web, al servidor en el que se almacena el Sitio Web o a cualquier servidor, computadora o base de datos conectados a él.</li>
                      <li type="disc">Atacar el sitio web mediante un ataque de denegación de servicio o un ataque distribuido de denegación de servicio.</li>
                      <li type="disc">Dañar o interrumpir cualquier parte del Sitio Web, cualquier equipo o red en la que se almacene el Sitio Web o cualquier software utilizado para la provisión del Sitio Web.</li>
                      </ul>
                      <br/>
                      <p>Un incumplimiento de esta cláusula puede ser un delito, de acuerdo a lo establecido en la Ley De Delitos Informáticos, Ley N° 30096 y sus modificatoria, así como lo establecido en la Ley internacional conocida como la Ley De Uso Indebido De Computadoras de 1990. Ante lo cual nos veremos obligados por Ley a reportar a las autoridades pertinentes el comportamiento ilícito, culposo o doloso de cualquier usuario, a las autoridades pertinentes, nacionales o internacionales, revelando su identidad a ellos. En el caso de tal incumplimiento, su derecho de usar el Sitio Web cesará inmediatamente.</p>
                      </li>
                      <li>Suspensión y/o cancelación del acceso al sitio web.
                      <br/><br/>
                        <p>Nos reservamos el derecho de terminar o suspender su acceso al Sitio Web inmediatamente y sin previo aviso a usted si:</p>
                        <p>Usted incumple con algún pago por servicio o producto requerido a nosotros o algún proveedor, cuando sea requerido.</p>
                        <p>Usted se está haciendo pasar por otra persona o falsea información relacionada a su identidad.</p>
                        <p>Cuando no nos proporcione, dentro de un plazo razonable, suficiente información que nos permita determinar la exactitud y validez de cualquier información proporcionada por usted o su identidad.</p>
                        <p>Sospechamos que usted ha participado, o está a punto de participar, directa, indirecta o de alguna manera, en actividades fraudulentas o ilegales en el Sitio Web.</p>
                      </li>
                      <li>Comentarios
                      <br/><br/>
                      <p>Usted reconoce que cualquier revisión, retroalimentación o calificación que usted deje puede ser publicada por nosotros en el Sitio Web y usted acepta que puede ser exhibido durante el tiempo que consideremos apropiado y que el contenido puede ser sindicado a otros sitios web, publicaciones o marketing.</p>
                      <p>Usted se compromete a que cualquier revisión, retroalimentación o calificación que escriba deberá:</p>
                      <ul>
                          <li type="disc">Cumplir con la Ley aplicable en Perú y la Ley en cualquier país desde el cual se publican.</li>
                          <li type="disc">Sea objetivamente exacto.</li>
                          <li type="disc">Contener opiniones genuinamente sostenidas (donde sea aplicable).
                            No debe contener ningún material que sea difamatorio, amenazador, obsceno, abusivo, ofensivo, odioso, inflamatorio o que pueda acosar, molestar, alarmar, avergonzar o invadir la privacidad de cualquier persona.
                            No promover ni abogar por un acto o actividad ilegal, discriminación y/o material sexualmente explícito o violencia.
                          </li>
                      </ul>
                      <br/>
                      <p>No debe infringir ninguna marca comercial, derechos de autor (incluidos derechos de diseño), derechos de base de datos u otros derechos de propiedad intelectual de cualquier otra persona o incumplimiento de cualquier obligación legal que deba a un tercero.</p>
                      <p>No debe usarse para hacerse pasar por otra persona, ni para falsear su identidad.
                         Usted acepta indemnizarnos y mantenernos libres de cualquier reclamación o acción presentada por terceros, que surja de cualquier revisión, retroalimentación o calificación publicada por usted en el Sitio Web, incluyendo, sin limitación, la violación de su privacidad, declaraciones o infracción de los derechos de propiedad intelectual.
                      </p>
                      <p>Usted nos concede a nosotros y a nuestros afiliados en general, una licencia mundial no exclusiva y libre de regalías para usar o editar cualquier comentario publicado por usted. </p>
                      <p>Nos reservamos el derecho de publicar, editar o eliminar cualquier comentario sin notificarlo.</p>
                      </li>
                      <li>Vinculación a la página web
                      <br/><br/>
                      <p>No debe crear un enlace a la página web desde otro sitio web, documento o cualquier otra fuente sin antes obtener nuestro previo consentimiento por escrito.</p>
                      <p>Cualquier enlace acordado debe ser:</p>
                      <ul>
                          <li type="disc">A la página principal de la web.</li>
                          <li type="disc">Establecido a partir de un sitio web o documento que es propiedad de usted y no contiene contenido que es ofensivo, polémico, infringe los derechos de propiedad intelectual u otros derechos de cualquier otra persona o no cumple de ninguna manera con la ley en Perú, específicamente con lo establecido en la Ley De Delitos Informáticos, Ley N° 30096 y sus modificatorias, así como lo establecido en la Ley internacional, conocida como la Ley De Uso Indebido De Computadoras de 1990.</li>
                          <li type="disc">Proporcionado de tal manera que sea justo y legal y que no dañe nuestra reputación ni se aproveche de ella.</li>
                          <li type="disc">Establecido de tal manera que no sugiera ninguna forma de asociación o aprobación de nuestra parte donde no exista.
                            No tenemos ninguna obligación de informarle si la dirección de la página principal del Sitio Web cambia y es su responsabilidad asegurarse de que cualquier enlace que proporcione a nuestra página principal sea correcto en todo momento.
                            Nos reservamos el derecho de retirar nuestro consentimiento sin previo aviso y sin proporcionar ninguna razón para el retiro. Al recibir dicha notificación, debe retirar inmediatamente el enlace e informarnos una vez realizado.
                          </li>
                      </ul>
                      <br/>
                      </li>
                      <li>Limitación de responsabilidad e indemnización
                      <br/><br/>
                      <p>No seremos responsables por contrato o agravio (incluyendo, sin limitación, negligencia), o con respecto a pre-contrato u otras representaciones (que no sean declaraciones falsas o fraudulentas), o de otra manera por las pérdidas ocasionadas,  que ha sufrido o incurrido que surjan de estas, en conexión con la provisión de cualquier asunto en estos términos y condiciones incluso si tales pérdidas son previsibles o resultan de una violación deliberada por nosotros o como resultado de cualquier acción que hemos tomado en respuesta a su incumplimiento:</p>
                      <ul>
                        <li type="disc">Cualquier pérdida económica (incluyendo sin limitación la pérdida de ingresos, beneficios, contratos, negocios o ahorros anticipados).</li>
                        <li type="disc">Cualquier pérdida de buena voluntad o reputación.</li>
                        <li type="disc">Cualquier pérdida especial o indirecta.</li>
                        <li type="disc">Cualquier pérdida de datos.</li>
                        <li type="disc">Falta de tiempo de gestión o de oficina.</li>
                        <li type="disc">Cualquier otra pérdida o daño de cualquier tipo.</li>
                        <li type="disc">Usted se compromete a indemnizar, defender y mantener a nuestros funcionarios, directores, empleados y proveedores, de inmediato y sin perjuicio de todas las reclamaciones, incluyendo pero no limitado a pérdidas (incluyendo pérdidas de ganancias, ingresos, buena voluntad o reputación) , costos y gastos, incluyendo costos administrativos y legales razonables, derivados de cualquier incumplimiento de estos términos y condiciones por usted, o cualquier otra responsabilidad derivada de su uso de este Sitio Web o de cualquier otra persona que acceda al Sitio Web usando su información personal con su autoridad.</li>
                        <li type="disc">Esta cláusula no afecta a sus derechos legales como consumidor.</li>
                      </ul>
                      <br/>  
                      </li>
                      <li>General
                      <br/><br/>
                      <p>Nos reservamos el derecho de cambiar la dirección de dominio de este Sitio Web y cualquier servicio, producto, precio de producto, especificaciones del producto y disponibilidad en cualquier momento.</p>
                      <p>Si alguna disposición de estos términos y condiciones es considerada por cualquier autoridad competente como inválida o inaplicable en todo o en parte, la validez de las demás disposiciones de estos términos y condiciones y el resto de la disposición en cuestión no se verán afectados.</p>
                      <p>Todos los contratos están concluidos y están disponibles únicamente en español.
                        Si fallamos, en cualquier momento, insistir en el estricto cumplimiento de cualquiera de sus obligaciones bajo estos términos y condiciones, o si no ejercitamos ninguno de los derechos o recursos a los cuales tenemos derecho bajo estos términos y condiciones, no constituirá una renuncia a tales derechos o recursos y no le aliviará del cumplimiento de sus obligaciones.
                      </p>
                      <p>Una renuncia por parte de nosotros ante cualquier incumplimiento no constituirá una renuncia de cualquier incumplimiento posterior.</p>
                      <p>Ninguna renuncia por parte de nosotros de cualquiera de estos términos y condiciones será efectiva a menos que se exprese expresamente que es una renuncia y se le comunica por escrito.</p>
                      </li>
                      <li>Ley aplicable y jurisdicción
                      <br/><br/>
                          <p>El sitio web es controlado y operado en Perú. Estos términos y condiciones se regirán por las Leyes de Peruanas y usted acepta irrevocablemente someterse a la jurisdicción exclusiva de los tribunales del país en mención.</p>
                      </li>
                      <li>Reembolsos y Política de Devoluciones
                      <br/><br/>
                            <p>Siempre haremos todo lo posible para preparar, empaquetar y entregar sus productos con el máximo cuidado. Sin embargo, los accidentes pueden ocurrir y por eso le pedimos que compruebe que su pedido este en óptimas condiciones antes de firmar su nota de entrega / descargo de responsabilidad. En el caso improbable de que encuentre que su pedido es incorrecto, incompleto o dañado al momento de la entrega, por favor informe a nuestro servicio al cliente inmediatamente al +51 930 303 295  y haremos todo lo posible para solucionar el incoveniente.</p>
                            <p>Lamentablemente nuestra política de empresa no acepta reembolsos. Sin embargo, damos la opción que  el cliente solicite otro pedido y pedidos por el mismo monto que ha pagado.</p>
                      </li>
                      <li>Haga Clic y Delivery
                      <br/><br/>
                          <p>Contamos con Delivery para algunos de nuestros productos. Las entregas son directamente a domicilio. lunes a Viernes de 10 AM a 7 PM y Sábados de 10 AM – 6 PM (consulte horario de días festivos).
                              Una vez entregado, la responsabilidad de cualquier daño a través del transporte recae en el cliente.</p>
                      </li>
                      <li>Entrega y Envío
                      <br/><br/>
                          <p>Contamos con un rango de Delivery, de 10 AM – 6 PM. No contamos con horario fijo. Los pedidos son entregados en función a la ruta de nuestro Courrier. En caso el cliente desee que su pedido llegue en hora exacto, el delivery tendrá costo diferente. Proporcione instrucciones claras de entrega y un número de contacto para nuestro conductor de entrega. Si, por alguna razón, la entrega no puede ser aceptada en el momento y lugar acordados, nuestro conductor le devolverá su pedido y lo almacenaremos solo por las próximas 24 horas, durante las cuales usted puede llamarnos para solicitar la re-entrega con un pago adicional. El costo de esta nueva entrega recae exclusivamente en el cliente.</p>
                      </li>
                    </ol>
                    
                </div>
            </div>
            <Sugar color= "#ffc107" customLoading={this.state.loading} />
            </div>
            <Footer />
        </section>

        )
    }
}