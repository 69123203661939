import React, {Component} from 'react';
import Common from "./Common";
import axios from "axios";
import DatePicker from "react-datepicker";

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class Datos extends Component{

    constructor(props) {
        super(props);
        
        this.state = { 
            userId :  '',
            nombre : '',
            apellidos : '',
            celular : '',
            email: '',
            cumpleano : '',
            emailGet : localStorage.getItem('email'),
            sesion : localStorage.getItem('sesion'),
            startDate: new Date(),
        };

    }

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    async componentDidMount(){

        if(this.state.sesion !== '1'){

            window.location.href = Common.urlRedirect;

        }else{
            await axios.get(`${Common.urlAPI}/Persona/Listar?Email=${this.state.emailGet}`,config).then((response) => {

                let source =  response.data.detail.map(item => {
                    return {
                        userId: item.userId,
                        nombre: item.nombre,
                        apellidos : item.apellidos,
                        celular : item.celular,
                        email : item.email,
                        fechaCumpleano : item.fechaCumpleano
                    };
                });
    
    
                this.setState({
                    userId : source[0].userId,
                    nombre : source[0].nombre,
                    apellidos : source[0].apellidos,
                    celular : source[0].celular,
                    email : source[0].email,
                    startDate : new Date(source[0].fechaCumpleano)
                })
            })
        }
        

    }

    setField (e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleChange = date => {
        this.setState({
          startDate: date
        });
    };

    handleEditar(e){
        e.preventDefault();

        this.JsonPersona = {
            "userId" : this.state.userId,
            "nombre" : this.state.nombre,
            "apellidos" : this.state.apellidos,
            "celular" : this.state.celular,
            "fechaCumpleano" : this.convert(this.state.startDate)
        }

        axios.post(`${Common.urlAPI}/Persona/Editar`, this.JsonPersona , config)
                .then((response) => {
                       if(response.status === 200){
                        Common.notify("Datos modificados correctamente", "success");
                       } 
                }).catch((error) =>{
                    Common.notify("Ups! Algo salió mal, Intente nuevamente", "error")
                })
    }

    render(){
        return(
            <div className="container-fluid bg-datos">
                <h1>Mi Cuenta</h1>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Nombres</label>
                            <input type="text" value={this.state.nombre} className="form-control" name="nombre" onChange={(e)=>this.setField(e)}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Apellidos</label>
                            <input type="text" value={this.state.apellidos}  className="form-control" name="apellidos" onChange={(e)=>this.setField(e)}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Celular</label>
                            <input type="number" value={this.state.celular}  className="form-control" name="celular" onChange={(e)=>this.setField(e)}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" value={this.state.email}  className="form-control" disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Fecha Cumpleaños</label>
                            <br/>
                            <DatePicker 
                                className="form-control calendar"
                                selected={this.state.startDate}
                                onChange={this.handleChange}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                    </div>
                </div>
                <br />
                <button className="btn btn-dolcesalato" onClick={(e) => this.handleEditar(e)}>ENVIAR</button>
            </div>

        )
    }
}