import React, {Component} from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Common from "./Common";
import axios from "axios";
import {Sugar} from 'react-preloaders';
import ClipLoader from "react-spinners/ClipLoader";

import mozos from "../images/servicios/mozos.jpg";

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class MozosBarman extends Component{

    constructor(props) {
        super(props);

        this.state = { 
            startDate: new Date(),
            nombres: '',
            celular : 0,
            email : '',
            tipoServicio : '',
            numeroMozos : '',
            numeroBarman: '',
            categoria: '',
            uniformados: '',
            numeroInvitados: '',
            descripcionAdicional: '',
            selectedValue : '',
            selectedValue1 : '',
            selectedValue2 : '',
            loading : false
        };

        this.optionsServicio = [
            {value: '1', label: 'Mozos'},
            {value: '2', label: 'Barman'},
            {value: '3', label: 'Ambos'},
        ]

        this.optionsCategoria = [
            {value: '1', label: 'Boda'},
            {value: '2', label: 'Quinceañero'},
            {value: '3', label: 'Fiesta Infantil'},
            {value: '4', label: 'Baby Shower'},
            {value: '5', label: 'Cocktails'},
            {value: '6', label: 'Aniversario'},
            {value: '7', label: 'Cumpleaños'},
            {value: '8', label: 'Conferencias'},
            {value: '9', label: 'Seminarios'},
            {value: '10', label: 'Ferias'},
            {value: '11', label: 'Otros'},
        ]

        this.optionsUniformados = [
            {value: '1', label: 'SI'},
            {value: '2', label: 'NO'}
        ]
        this.handleChangeServicio = this.handleChangeServicio.bind(this);
        this.handleChangeCategoria = this.handleChangeCategoria.bind(this);
        this.handleChangeUniformados = this.handleChangeUniformados.bind(this);
    }
      
    handleChange = date => {
        this.setState({
          startDate: date
        });
    };


    handleChangeServicio(selectedOption) {

        this.setState({ selectedValue : selectedOption.value, tipoServicio: selectedOption.label});
    }

    handleChangeCategoria(selectedOption) {

        this.setState({ selectedValue1 : selectedOption.value, categoria: selectedOption.label});
    }


    handleChangeUniformados(selectedOption) {

        this.setState({ selectedValue2 : selectedOption.value, uniformados: selectedOption.label});
    }

    setField (e) {
        this.setState({[e.target.name]: e.target.value});
    }

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    handleClick(event){
        event.preventDefault();
        
        const {nombres, celular, email, tipoServicio, numeroMozos, numeroBarman, categoria, uniformados, numeroInvitados, startDate, descripcionAdicional} = this.state;
        
        if(nombres !== '' && celular !== 0 && email !== '' && tipoServicio !== ''  && categoria !== '' && uniformados !== '' && numeroInvitados !== ''){
            this.setState({ loading : true });
            if(celular.length === 9 && /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){

                this.JsonDecoracion = {
                    'nombres' : nombres,
                    'celular' : celular,
                    'email' : email,
                    'tipoServicio' : tipoServicio,
                    'numeroMozos' : numeroMozos,
                    'numeroBarman' : numeroBarman,
                    'categoria' : categoria,
                    'uniformados' :  uniformados,
                    'numeroPersonas' : numeroInvitados,
                    'fechaEvento' : this.convert(startDate),
                    'descripcionAdicional' : descripcionAdicional
                }
         
                axios.post(`${Common.urlAPI}/EnviarCorreo/Mozos`, this.JsonDecoracion , config)
                         .then((response) => {
                               
                             const { history } = this.props
                             history.push('/graciasServiciosAdicionales')
                         }).catch((error) =>{
                            this.setState({ loading : false });
                            Common.notify("Ocurrio un error vuelve a intentarlo","error");
                        })

            }else{
                Common.notify("Ingresar el formato correcto","error");
            }
        }else{
            Common.notify("Llenar los campos solicitados", "error")
        }
        
     }

    render(){
        return(
            <section className="col-md-12 col-lg-12">
                <div className="container">
                <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+mozos+")", backgroundSize : "cover"}}>
                        <h4>Mozos y Barman</h4>
                        <div className="row">
                            <div className="col-md-12 frm-serviciosa">
                                <form>
                                    <div className="form-group">
                                        <label>Nombre o Empresa</label>
                                        <input type="text" className="form-control" placeholder="Nombres o Empresa" name="nombres" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Número celular</label>
                                        <input type="number" className="form-control" placeholder="Número Celular" name="celular" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Correo electrónico</label>
                                        <input type="text" className="form-control" placeholder="Correo Electrónico" name="email" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Tipo de Servicio</label>
                                        <Select 
                                        value={this.optionsServicio.filter(({value}) => value === this.state.selectedValue)}
                                        onChange={this.handleChangeServicio}
                                        placeholder = {'Seleccionar'}
                                        options = {this.optionsServicio}
                                        className="select"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Número Mozos</label>
                                        <input type="number" className="form-control" placeholder="Número Mozos" name="numeroMozos" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Número Barman</label>
                                        <input type="number" className="form-control" placeholder="Número Barman" name="numeroBarman" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Categoria: </label>
                                        <Select 
                                        value={this.optionsCategoria.filter(({value}) => value === this.state.selectedValue1)}
                                        onChange={this.handleChangeCategoria}
                                        placeholder = {'Seleccionar'}
                                        options = {this.optionsCategoria}
                                        className="select" />
                                    </div>
                                    <div className="form-group">
                                        <label>Uniformados</label>
                                        <Select 
                                         value={this.optionsUniformados.filter(({value}) => value === this.state.selectedValue2)}
                                         onChange={this.handleChangeUniformados}
                                        options = {this.optionsUniformados}
                                        placeholder = {'Seleccionar'}
                                        className="select" />
                                    </div>
                                    <div className="form-group">
                                        <label>Número de Invitados</label>
                                        <input type="number" className="form-control" placeholder="Número Invitados" name="numeroInvitados" onChange={(e)=>this.setField(e)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Fecha del Evento</label><br/>
                                        <DatePicker 
                                            className="form-control calendar"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            dateFormat="d/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Descripción Adicional</label>
                                        <textarea className="form-control"  rows="3" name="descripcionAdicional" onChange={(e)=>this.setField(e)}></textarea>
                                    </div>
                                    <br />
                                    {
                                        this.state.loading ? <button disabled={true} style={{opacity : "0.5", cursor : "default"}} className="sc-htpNat btn-saenviar" onClick={(e) => this.handleClick(e)}>ENVIAR&nbsp;&nbsp;<ClipLoader size={25} loading={this.state.loading} /></button>
                                        
                                        : <button className="sc-htpNat btn-saenviar" onClick={(e) => this.handleClick(e)}>ENVIAR</button>
                                    }
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Sugar color= "#ffc107" customLoading={this.state.loading} />
            </section>
        )
    }
    
}
