import React, {Component} from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import decoracion from "../images/servicios/decoracion.jpg";
import mozos from "../images/servicios/mozos.jpg";
import comida from "../images/servicios/comida.jpg"
import coffe from "../images/servicios/coffe.jpg"
import carrito from "../images/servicios/snack.jpg"
import degustacion from "../images/servicios/degustaciones.jpg"

export default class ServiciosAdicionales extends Component{

    constructor(props) {
        super(props);

        const servicioAdicional = props.location.state.servicio;

        this.state = { 
            servicio : servicioAdicional,
            startDate: new Date()
        };
    }
      
    handleChange = date => {
        this.setState({
          startDate: date
        });
    };


    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar los props):
        if (this.props.location.state.servicio !== prevProps.location.state.servicio ){

                this.setState({servicio : this.props.location.state.servicio});
        }
    }

    render(){
        return(
            <section className="col-md-12 col-lg-12">
                <div className="container">
                    {
                        (this.state.servicio === '1')? 

                        <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+decoracion+")", backgroundSize : "cover"}}>
                        <h4>Decoración y Menaje</h4>
                        <div className="row">
                            <div className="col-md-12 frm-serviciosa">
                                <form>
                                    <div className="form-group">
                                        <label>Nombres y Apellidos</label>
                                        <input type="text" className="form-control" placeholder="Nombres y Apellidos" />
                                    </div>
                                    <div className="form-group">
                                        <label>Numero Celular</label>
                                        <input type="number" className="form-control" placeholder="Numero Celular" />
                                    </div>
                                    <div className="form-group">
                                        <label>Tu correo electronico</label>
                                        <input type="text" className="form-control" placeholder="Correo Electronico" />
                                    </div>
                                    <div className="form-group">
                                        <label>Tipo de decoración</label>
                                        <Select className="select" />
                                    </div>
                                    <div className="form-group">
                                        <label>Fecha del Evento</label><br/>
                                        <DatePicker 
                                            className="form-control calendar"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            dateFormat="d/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Descripción Adicional</label>
                                        <textarea className="form-control"  rows="3"></textarea>
                                    </div>
                                    <br />
                                    <button className="sc-htpNat btn-saenviar">ENVIAR</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    :

                    (this.state.servicio === '2')?

                    <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+mozos+")", backgroundSize : "cover"}}>
                        <h4>Mozos y Barman</h4>
                        <div className="row">
                            <div className="col-md-12 frm-serviciosa">
                                <form>
                                    <div className="form-group">
                                        <label>Nombre o Empresa</label>
                                        <input type="text" className="form-control" placeholder="Nombres o Empresa" />
                                    </div>
                                    <div className="form-group">
                                        <label>Numero Telefono</label>
                                        <input type="number" className="form-control" placeholder="Numero Telefono" />
                                    </div>
                                    <div className="form-group">
                                        <label>Correo electronico</label>
                                        <input type="text" className="form-control" placeholder="Correo Electronico" />
                                    </div>
                                    <div className="form-group">
                                        <label>Tipo de Servicio</label>
                                        <Select className="select" />
                                    </div>
                                    <div className="form-group">
                                        <label>Numero Mozos</label>
                                        <input type="number" className="form-control" placeholder="Numero Mozos" />
                                    </div>
                                    <div className="form-group">
                                        <label>Numeros Barman</label>
                                        <input type="number" className="form-control" placeholder="Numeros Barman" />
                                    </div>
                                    <div className="form-group">
                                        <label>Tipo de Servicio</label>
                                        <Select className="select" />
                                    </div>
                                    <div className="form-group">
                                        <label>Uniformados</label>
                                        <Select className="select" />
                                    </div>
                                    <div className="form-group">
                                        <label>Numeros de Invitados</label>
                                        <input type="number" className="form-control" placeholder="Numeros Invitados" />
                                    </div>
                                    <div className="form-group">
                                        <label>Fecha del Evento</label><br/>
                                        <DatePicker 
                                            className="form-control calendar"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            dateFormat="d/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Descripción Adicional</label>
                                        <textarea className="form-control"  rows="3"></textarea>
                                    </div>
                                    <br />
                                    <button className="sc-htpNat btn-saenviar">ENVIAR</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    :

                    (this.state.servicio === '3')?

                    <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+comida+")", backgroundSize : "cover"}}>
                        <h4>Comida para Eventos</h4>
                        <div className="row">
                            <div className="col-md-12 frm-serviciosa">
                                <form>
                                    <div className="form-group">
                                        <label>Nombre o Empresa</label>
                                        <input type="text" className="form-control" placeholder="Nombres o Empresa" />
                                    </div>
                                    <div className="form-group">
                                        <label>Numero Telefono</label>
                                        <input type="number" className="form-control" placeholder="Numero Telefono" />
                                    </div>
                                    <div className="form-group">
                                        <label>Correo electronico</label>
                                        <input type="text" className="form-control" placeholder="Correo Electronico" />
                                    </div>
                                    <div className="form-group">
                                        <label>Categoria</label>
                                        <Select className="select" />
                                    </div>
                                    <div className="form-group">
                                        <label>Numero Personas</label>
                                        <input type="number" className="form-control" placeholder="Numero Personas" />
                                    </div>
                                    <div className="form-group">
                                        <label>Fecha del Evento</label><br/>
                                        <DatePicker 
                                            className="form-control calendar"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            dateFormat="d/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Descripción Adicional</label>
                                        <textarea className="form-control"  rows="3"></textarea>
                                    </div>
                                    <br />
                                    <button className="sc-htpNat btn-saenviar">ENVIAR</button>
                                </form>
                            </div>
                        </div>
                    </div>


                     :

                     (this.state.servicio === '4')?

                     <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+coffe+")", backgroundSize : "cover"}}>
                         <h4>Coffee Break</h4>
                         <div className="row">
                             <div className="col-md-12 frm-serviciosa">
                                 <form>
                                     <div className="form-group">
                                         <label>Nombre o Empresa</label>
                                         <input type="text" className="form-control" placeholder="Nombres o Empresa" />
                                     </div>
                                     <div className="form-group">
                                         <label>Numero Telefono</label>
                                         <input type="number" className="form-control" placeholder="Numero Telefono" />
                                     </div>
                                     <div className="form-group">
                                         <label>Correo electronico</label>
                                         <input type="text" className="form-control" placeholder="Correo Electronico" />
                                     </div>
                                     <div className="form-group">
                                         <label>Tipo Evento</label>
                                         <Select className="select" />
                                     </div>
                                     <div className="form-group">
                                         <label>Lugar del Evento</label>
                                         <input type="text" className="form-control" placeholder="Lugar del Evento" />
                                     </div>
                                     <div className="form-group">
                                         <label>Numero Invitados</label>
                                         <input type="number" className="form-control" placeholder="Numero Invitados" />
                                     </div>
                                     <div className="form-group">
                                         <label>Fecha del Evento</label><br/>
                                         <DatePicker 
                                             className="form-control calendar"
                                             selected={this.state.startDate}
                                             onChange={this.handleChange}
                                             dateFormat="d/MM/yyyy"
                                         />
                                     </div>
                                     <div className="form-group">
                                         <label>Descripción Adicional</label>
                                         <textarea className="form-control"  rows="3"></textarea>
                                     </div>
                                     <br />
                                     <button className="sc-htpNat btn-saenviar">ENVIAR</button>
                                 </form>
                             </div>
                         </div>
                     </div>
 
 
                      :
 
                      (this.state.servicio === '5')?

                      <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+carrito+")", backgroundSize : "cover"}}>
                          <h4>Snack Cart</h4>
                          <div className="row">
                              <div className="col-md-12 frm-serviciosa">
                                  <form>
                                      <div className="form-group">
                                          <label>Nombre o Empresa</label>
                                          <input type="text" className="form-control" placeholder="Nombres o Empresa" />
                                      </div>
                                      <div className="form-group">
                                          <label>Numero Telefono</label>
                                          <input type="number" className="form-control" placeholder="Numero Telefono" />
                                      </div>
                                      <div className="form-group">
                                          <label>Correo electronico</label>
                                          <input type="text" className="form-control" placeholder="Correo Electronico" />
                                      </div>
                                      <div className="form-group">
                                          <label>Tipo Evento</label>
                                          <Select className="select" />
                                      </div>
                                      <div className="form-group">
                                          <label>Lugar del Evento</label>
                                          <input type="text" className="form-control" placeholder="Lugar del Evento" />
                                      </div>
                                      <div className="form-group">
                                          <label>Numero Invitados</label>
                                          <input type="number" className="form-control" placeholder="Numero Invitados" />
                                      </div>
                                      <div className="form-group">
                                          <label>Fecha del Evento</label><br/>
                                          <DatePicker 
                                              className="form-control calendar"
                                              selected={this.state.startDate}
                                              onChange={this.handleChange}
                                              dateFormat="d/MM/yyyy"
                                          />
                                      </div>
                                      <div className="form-group">
                                          <label>Descripción Adicional</label>
                                          <textarea className="form-control"  rows="3"></textarea>
                                      </div>
                                      <br />
                                      <button className="sc-htpNat btn-saenviar">ENVIAR</button>
                                  </form>
                              </div>
                          </div>
                      </div>
  
  
                       :
  
                       (this.state.servicio === '6')?

                       <div className="cb-servicios" id="bodyServicios" style={{backgroundImage: "url("+degustacion+")", backgroundSize : "cover"}}>
                           <h4>Degustaciones</h4>
                           <p style={{textAlign: "justify"}}>Solicita una degustación gratuita para tu oficina. Te ofrecemos mini cupcakes acompañados de café para tus colaboradores. La empresa solo tendrá 
                               la opción de pedir una degustación gratuita al año.
                           </p>
                           <div className="row">
                               <div className="col-md-12 frm-serviciosa">
                                   <form>
                                       <div className="form-group">
                                           <label>Empresa</label>
                                           <input type="text" className="form-control" placeholder="Nombre de Empresa" />
                                       </div>
                                       <div className="form-group">
                                           <label>Numero RUC</label>
                                           <input type="number" className="form-control" placeholder="Numero Ruc" />
                                       </div>
                                       <div className="form-group">
                                           <label>Numero Telefono</label>
                                           <input type="number" className="form-control" placeholder="Numero Telefono" />
                                       </div>
                                       <div className="form-group">
                                           <label>Correo electronico</label>
                                           <input type="text" className="form-control" placeholder="Correo Electronico" />
                                       </div>
                                       <div className="form-group">
                                           <label>Fecha de la Degustación</label><br/>
                                           <DatePicker 
                                               className="form-control calendar"
                                               selected={this.state.startDate}
                                               onChange={this.handleChange}
                                               dateFormat="d/MM/yyyy"
                                           />
                                       </div>
                                       <div className="form-group">
                                           <label>Descripción Adicional</label>
                                           <textarea className="form-control"  rows="3"></textarea>
                                       </div>
                                       <br />
                                       <button className="sc-htpNat btn-saenviar">ENVIAR</button>
                                   </form>
                               </div>
                           </div>
                       </div>
   
   
                        :
   
                         undefined
                    }
                   
                </div>

            </section>
        )
    }
}