import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Common from './Common'
import MisPedidos from './MisPedidos'

import Datos from './Datos'

export default class MiCuenta extends Component{

    constructor(props) {
        super(props);
        

        this.state = { 
            nombre : '',
            apellidos : '',
            celular : '',
            email: '',
            cumpleano : '',
            estado : 0
        };

    }

    cerrarSesion(e){
     e.preventDefault();
     localStorage.removeItem('sesion');
     window.location.href = Common.urlRedirect
     
    }

    cambiarEstado(e, estado){
      e.preventDefault();

      if(estado === 0){
          this.setState({estado : 0})
      }else{
          this.setState({estado : 1})
      }
    }

    render(){
        return(

        <section className="col-md-12 col-lg-12 bg-section">
            <div className="container bg-login">
                <div className="container">
                    <div className="row">
                         <div className="col-md-3 borde-login">
                            <Link onClick={(e) => this.cambiarEstado(e,0)}><i class="fas fa-user-alt"></i>&nbsp; Mi cuenta</Link>
                            <Link onClick={(e) => this.cambiarEstado(e,1)}><i class="fas fa-archive"></i>&nbsp; Mis pedidos</Link>
                            <Link onClick={(e) => this.cerrarSesion(e)}><i class="fas fa-door-open"></i> Cerrar sesión</Link>
                         </div>
                         <div className="col-md-9">
                             {
                                 (this.state.estado === 0)?
                                 
                                  <Datos />

                                  :

                                  <MisPedidos />
                             }
                             
                         </div>
                    </div>
                </div>
            </div>
        </section>

        )
    }
}